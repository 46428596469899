@charset "UTF-8";
/* ==========================================================================
  default css
============================================================================*/
/* ==========================================================================
  202403 renewal
============================================================================*/
div.f-contact:not(.footer202403__contact) {
  display: none !important;
}

/* _::-webkit-full-page-media, _:future, :root * {
  min-height: 0vw;
} */
html {
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

@media screen and (min-width: 769px) {
  .pc-only {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .sp-only {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

.test {
  border: 1px solid orange;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

/* side-menu */
.side-menu__subul {
  padding-left: 2em;
}
.side-menu__subul > li.under {
  border-bottom: none;
}
.side-menu__subul > li.under:last-child a {
  -webkit-padding-after: 25px;
  padding-block-end: 25px;
  background-position-y: 0.6em !important;
}
.side-menu__subul > li.under a {
  padding-block: 0;
}
.side-menu .pb0 {
  -webkit-padding-after: 0 !important;
  padding-block-end: 0 !important;
  background-position-y: 2.1em !important;
}

.header202403 {
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  /* logo */
  /* gnav */
}
@media screen and (min-width: 1201px) {
  .header202403 {
    height: 150px;
    padding-inline: min(15px, 5.2083333333vw);
  }
}
@media screen and (max-width: 1200px) {
  .header202403 {
    height: 70px !important;
    padding-inline: 15px;
  }
}
.header202403 > .container-l {
  gap: 30px;
  width: 100%;
  max-width: 1720px;
  height: 100% !important;
  margin-inline: auto;
  padding-inline: 0;
}
.header202403 .h-logo {
  width: auto !important;
  margin-block: auto;
  padding: 0 !important;
}
@media screen and (min-width: 1201px) {
  .header202403 .h-logo {
    height: 84%;
    aspect-ratio: 156/126;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .h-logo {
    height: 60%;
    aspect-ratio: 263/42;
  }
}
.header202403 .h-logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.header202403 .gnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: auto !important;
  max-width: 100% !important;
  /* sns */
  /* main nav */
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 0;
    width: auto;
  }
}
@media screen and (max-width: 1350px) {
  .header202403 .gnav {
    width: auto;
    max-width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
    min-width: 74.1025641026% !important;
  }
}
@media screen and (max-width: 500px) {
  .header202403 .gnav {
    padding-bottom: 40px !important;
  }
}
.header202403 .gnav-sns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav-sns {
    /* height: clamp(45px, vwRatioPC(70), 70px);
    flex-shrink: 0; */
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav-sns {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /*  width: 90%; */
    max-width: 208px;
    height: auto;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
.header202403 .gnav-sns__li {
  width: clamp(80px, 4.6875vw, 100px);
}
.header202403 .gnav-sns img {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.header202403 .gnav .nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto !important;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-top: 110px !important;
  }
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list__item {
    padding-inline: 15px;
    border-left: none !important;
    text-align: center;
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list__item {
    padding: 24px 0;
    text-align: left;
  }
}
.header202403 .gnav .nav-list__item:first-of-type > a::before {
  background-image: url(../img/common/h-nav-icon01.webp);
}
.header202403 .gnav .nav-list__item:nth-of-type(2) > a::before {
  background-image: url(../img/common/h-nav-icon02.webp);
}
.header202403 .gnav .nav-list__item:nth-of-type(3) > a::before {
  background-image: url(../img/common/h-nav-icon03.webp);
}
.header202403 .gnav .nav-list__item:nth-of-type(4) > a::before {
  background-image: url(../img/common/h-nav-icon04.webp);
}
.header202403 .gnav .nav-list__item:nth-of-type(5) > a::before {
  background-image: url(../img/common/h-nav-icon05.webp);
}
.header202403 .gnav .nav-list__item:nth-of-type(6) > a::before {
  background-image: url(../img/common/h-nav-icon06.webp);
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list__item > a::before {
    display: block;
    content: "";
    width: clamp(40px, 3.125vw, 70px);
    height: clamp(40px, 3.125vw, 70px);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    margin-inline: auto;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list__item > a::before {
    display: none;
  }
}
.header202403 .gnav .nav-list__item::after {
  display: block;
  content: "";
  background-color: #DDDDDD;
  position: absolute;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list__item::after {
    width: 1px;
    height: 30px;
    right: 0;
    bottom: 0.5em;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list__item::after {
    width: 30px;
    height: 1px;
    left: 0;
    bottom: 0;
  }
}
.header202403 .gnav .nav-list__item:first-of-type {
  padding-top: 0 !important;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list__item:last-of-type::after {
    display: none;
  }
}
.header202403 .gnav .nav-list__item > a {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list__item > a {
    /* display: flex;
    align-items: center;
    flex-direction: column; */
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;
    /* height: 2.5em; */
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media screen and (max-width: 1512px) {
  .header202403 .gnav .nav-list__item > a {
    font-size: 1.4rem !important;
  }
}
@media screen and (max-width: 1360px) {
  .header202403 .gnav .nav-list__item > a {
    font-size: 1.2rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list__item > a {
    font-size: 1.6rem !important;
    line-height: 1.375;
  }
}
.header202403 .gnav .nav-list__item > a > span {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list .pc-only {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list .pc-only {
    display: none;
  }
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav .nav-list .sp-only {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav .nav-list .sp-only {
    display: inline;
  }
}
.header202403 .gnav-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  width: auto !important;
  border-bottom: none !important;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav-box {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav-box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
.header202403 .gnav-box__telarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav-box__telarea {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav-box__telarea {
    gap: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media screen and (max-width: 1512px) {
  .header202403 .gnav-box__telarea .tel {
    font-size: 2.8rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav-box__telarea .tel {
    font-size: 3rem !important;
  }
}
.header202403 .gnav-box__telarea .tel > i {
  font-size: 1.8rem;
}
@media screen and (max-width: 1512px) {
  .header202403 .gnav-box__telarea .fz-change {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media screen and (max-width: 1512px) {
  .header202403 .gnav-box__telarea .fz-change > .bold {
    width: auto !important;
    text-align: center;
  }
}
.header202403 .gnav-box__buttonarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (min-width: 1201px) {
  .header202403 .gnav-box__buttonarea {
    gap: 0;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav-box__buttonarea {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    margin-top: 0;
  }
}
.header202403 .gnav-box__buttonarea .btn01 {
  min-width: 170px;
}
@media screen and (max-width: 1512px) {
  .header202403 .gnav-box__buttonarea .btn01 {
    min-width: 150px;
  }
}
@media screen and (max-width: 1360px) {
  .header202403 .gnav-box__buttonarea .btn01 {
    min-width: 140px;
  }
}
@media screen and (max-width: 1200px) {
  .header202403 .gnav-box__buttonarea .btn01 {
    min-width: 170px;
    width: 100%;
  }
}
.header202403 .gnav-box__buttonarea .btn01.--lock {
  background-color: #ffffff;
  border: 1px solid #eb6877;
  color: #eb6877;
}
.header202403 .gnav-box__buttonarea .btn01.--lock > i {
  display: inline-block;
  -webkit-margin-end: 0.5em;
  margin-inline-end: 0.5em;
  color: #eb6877;
}

/* safari */
@media (min-width: 769px) and (max-width: 1300px) {
  _::-webkit-full-page-media, _:future, :root .header202403 .h-logo {
    height: 60% !important;
  }
  _::-webkit-full-page-media, _:future, :root .header202403 > .container-l {
    gap: 15px !important;
  }
}
/* Firefox */
@media (min-width: 769px) and (max-width: 1300px) {
  @-moz-document url-prefix() {
    .header202403 .h-logo {
      height: 60% !important;
    }
    .header202403 > .container-l {
      gap: 15px !important;
    }
  }
}
@media screen and (min-width: 769px) {
  .footer202403 {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .footer202403 {
    margin-bottom: 90px;
  }
}
.footer202403__contact {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
@media screen and (min-width: 769px) {
  .footer202403__contact {
    margin: 120px 0;
    padding: 135px 0 70px;
    background-image: url(../img/common/footer-bg-pc.png);
  }
}
@media screen and (max-width: 768px) {
  .footer202403__contact {
    margin: 90px 0;
    padding: 66px 24px 55px;
    background-image: url(../img/common/footer-bg-sp.png);
  }
}
.footer202403__contact p {
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}
.footer202403__contact p + p {
  margin-top: 8px;
}
.footer202403__contact__contarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (min-width: 769px) {
  .footer202403__contact__contarea {
    gap: 44px;
    margin-top: 27px;
  }
}
@media screen and (max-width: 768px) {
  .footer202403__contact__contarea {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 19px;
    margin-top: 33px;
  }
}
.footer202403__contact__contarea.contact-box {
  background: none !important;
  border-radius: 0 !important;
}
@media screen and (min-width: 769px) {
  .footer202403__contact__contarea.contact-box {
    padding: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .footer202403__contact__contarea.contact-box {
    padding: 0 12px !important;
  }
}
.footer202403__contact__contarea.contact-box li {
  width: auto !important;
}
.footer202403__contact__contarea.contact-box li.footer-mail {
  width: 340px !important;
  max-width: 100%;
}
.footer202403__contact__contarea.contact-box .tel {
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .footer202403__contact__contarea.contact-box .tel {
    font-size: 4rem !important;
  }
}
@media screen and (min-width: 769px) {
  .footer202403__map iframe {
    aspect-ratio: 1920/623;
  }
}
@media screen and (max-width: 768px) {
  .footer202403__map iframe {
    aspect-ratio: 390/300;
  }
}

.top202403kv .swiper-button-prev,
.top202403kv .swiper-button-next {
  color: #ffffff;
}
.top202403kv .swiper-pagination .swiper-pagination-bullet {
  border: 1px solid #eb6877;
  background-color: rgba(235, 104, 119, 0);
  opacity: 1;
  position: relative;
}
@media screen and (min-width: 769px) {
  .top202403kv .swiper-pagination .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
  }
}
@media screen and (max-width: 768px) {
  .top202403kv .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }
}
@media screen and (min-width: 769px) {
  .top202403kv .swiper-pagination .swiper-pagination-bullet::after {
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgba(235, 104, 119, 0);
    border-radius: 50%;
    position: absolute;
    inset: 0;
    margin: auto;
  }
}
.top202403kv .swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgba(235, 104, 119, 0);
  position: relative;
}
@media screen and (max-width: 768px) {
  .top202403kv .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #eb6877;
  }
}
@media screen and (min-width: 769px) {
  .top202403kv .swiper-pagination .swiper-pagination-bullet-active::after {
    background-color: #eb6877;
  }
}
.top202403news {
  /* tab */
  /* slider */
  /* slider content */
}
@media screen and (min-width: 769px) {
  .top202403news {
    margin-top: 120px;
  }
}
@media screen and (max-width: 1200px) {
  .top202403news {
    margin-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news {
    margin-top: 50px;
  }
}
.top202403news .--event {
  --color: #eb6877;
  --subcolor: #FCF5F5;
}
.top202403news .--news {
  --color: #80C269;
  --subcolor: #F0FAF0;
}
.top202403news__tab__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-inline: 15px;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__menu {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
  }
}
.top202403news__tab__menu > li {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__menu > li {
    max-width: 480px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__menu > li {
    max-height: 81.0256410256vw;
  }
}
.top202403news__tab__item {
  display: block;
  width: 100%;
  padding: 11px;
  background-color: var(--color);
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
}
.top202403news__tab__item[aria-expanded=false] {
  background-color: var(--subcolor);
  color: var(--color);
}
.top202403news__tab__item[aria-expanded=false] .top202403news__tab__item-main {
  color: var(--color);
}
.top202403news__tab__item-main {
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__item-main {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__item-main {
    font-size: 1.5rem;
  }
}
.top202403news__tab__item-main > .small {
  display: inline-block;
  -webkit-margin-start: 0.5em;
  margin-inline-start: 0.5em;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__item-main > .small {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__item-main > .small {
    font-size: 1rem;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}
.top202403news__tab__item-main > img {
  display: inline-block;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__item-main > img {
    width: 48px;
    -webkit-margin-end: 18px;
    margin-inline-end: 18px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__item-main > img {
    width: 32px;
    -webkit-margin-end: 12px;
    margin-inline-end: 12px;
  }
}
.top202403news__tab__body {
  display: none;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__body {
    -webkit-margin-before: 42px;
    margin-block-start: 42px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__body {
    -webkit-margin-before: 30px;
    margin-block-start: 30px;
  }
}
.top202403news__tab__body__inner {
  -webkit-padding-after: 40px;
  padding-block-end: 40px;
  position: relative;
}
@media screen and (min-width: 769px) {
  .top202403news__tab__body__inner {
    padding-inline: 52px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__tab__body__inner {
    padding-inline: 36px;
  }
}
.top202403news__tab .u-block {
  display: block;
}
.top202403news__tab .u-none {
  display: none;
}
.top202403news .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  background-color: var(--subcolor);
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
}
@media screen and (min-width: 769px) {
  .top202403news .swiper-slide {
    padding: 16px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news .swiper-slide {
    padding: 12px;
  }
}
.top202403news__swiper-pagination {
  top: inherit !important;
  bottom: 0 !important;
}
.top202403news .swiper-button {
  aspect-ratio: 1;
  background-color: #C3C3C3;
  color: #ffffff;
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .top202403news .swiper-button {
    width: 40px;
  }
}
@media screen and (max-width: 768px) {
  .top202403news .swiper-button {
    width: 29px;
  }
}
.top202403news .swiper-button::after {
  font-size: 20px;
}
.top202403news .swiper-button-prev {
  left: 0;
}
.top202403news .swiper-button-next {
  right: 0;
}
.top202403news .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border: 1px solid var(--color);
}
.top202403news .swiper-pagination-bullet-active {
  background-color: var(--color);
}
.top202403news__thumbnail {
  width: 100%;
  aspect-ratio: 407/249;
  margin-bottom: 18px;
}
.top202403news__thumbnail > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.top202403news__subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.6em;
}
.top202403news__cat {
  display: inline-block;
  padding: 0.5em 1.5em;
  background-color: var(--color);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  >span {
    padding: 0 .1em;
    display: inline-block;
  }
}
.top202403news__date {
  font-family: "Roboto", sans-serif;
  color: var(--color);
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .top202403news__date {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__date {
    font-size: 1.3rem;
  }
}
.top202403news__new {
  font-family: "Roboto", sans-serif;
  color: #FFBF00;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .top202403news__new {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__new {
    font-size: 1.2rem;
  }
}
.top202403news__title {
  display: block;
  margin-block: 4px;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .top202403news__title {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__title {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.top202403news__title.--news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  margin-top: 8px;
}
.top202403news__title__link {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6428571429;
  text-decoration: underline;
}
.top202403news__title > span {
  display: block;
  width: 16px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.top202403news__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: auto 0 0 auto;
  background-color: var(--color);
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .top202403news__button {
    width: 120px;
    height: 40px;
    font-weight: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403news__button {
    width: 85px;
    height: 28px;
    font-size: 1rem;
  }
}
.top202403news__tab__body .swiper-wrapper {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.top202403news__tab__body .swiper-slide {
  height: auto;
}
.top202403news .btn01 {
  max-width: 300px;
  -webkit-margin-before: 24px;
  margin-block-start: 24px;
  margin-inline: auto;
  background-color: var(--color);
}
.top202403news .btn01 > i {
  display: inline-block;
  -webkit-margin-end: 0.5em;
  margin-inline-end: 0.5em;
}
.top202403bunner ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 90px;
}
@media screen and (min-width: 769px) {
  .top202403bunner ul {
    gap: 40px;
  }
}
@media screen and (max-width: 768px) {
  .top202403bunner ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }
}
.top202403bunner ul li {
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
@media screen and (min-width: 769px) {
  .top202403bunner ul li {
    max-width: 620px;
    width: 32.2916666667vw;
  }
}
@media screen and (max-width: 768px) {
  .top202403bunner ul li {
    width: 92.3076923077vw;
    margin-inline: auto;
  }
}
.top202403bunner ul li img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
@media screen and (min-width: 769px) {
  .top202403about {
    margin-top: min(190px, 9.8958333333vw);
  }
}
@media screen and (max-width: 768px) {
  .top202403about {
    margin-top: 90px;
  }
}
.top202403about__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 768px) {
  .top202403about__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media screen and (min-width: 769px) {
  .top202403about__img {
    width: 50%;
    -webkit-padding-end: 2.0833333333vw;
    padding-inline-end: 2.0833333333vw;
  }
}
@media screen and (max-width: 768px) {
  .top202403about__img {
    width: 100%;
  }
}
.top202403about__contarea {
  background-repeat: no-repeat;
}
@media screen and (min-width: 769px) {
  .top202403about__contarea {
    width: 50%;
    padding-inline: 2.0833333333vw 2.6041666667vw;
    background-image: url(../img/top/about-bg-pc.webp);
    background-size: 60% auto;
    background-position-x: 80%;
  }
}
@media screen and (max-width: 768px) {
  .top202403about__contarea {
    width: 100%;
    -webkit-padding-before: 16px;
    padding-block-start: 16px;
    padding-inline: 15px;
    background-image: url(../img/top/about-bg-sp.webp);
    background-size: 100% auto;
    background-position: 95% 16px;
  }
}
.top202403about__title {
  font-weight: 700;
  letter-spacing: 0.02em;
  text-decoration: underline;
}
@media screen and (min-width: 769px) {
  .top202403about__title {
    font-size: 3.6rem;
    line-height: 1.5555555556;
  }
}
@media screen and (max-width: 1200px) {
  .top202403about__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403about__title {
    font-size: 2.8rem;
    line-height: 1.5;
  }
}
.top202403about__title > span {
  color: #eb6877;
}
.top202403about__cont {
  display: block;
  margin-top: 40px;
  font-weight: 700;
  line-height: 2;
}
@media screen and (min-width: 769px) {
  .top202403about__cont {
    max-width: 640px;
    width: 74%;
    font-size: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .top202403about__cont {
    width: 90%;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403about__cont {
    width: 100%;
    font-size: 1.6rem;
  }
}
.top202403about__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 300px;
  margin-top: 60px;
  margin-inline: auto;
  padding: 1em !important;
}
.top202403about__button i {
  display: inline-block;
  -webkit-margin-start: 0.5em;
  margin-inline-start: 0.5em;
}
.top202403message {
  background-color: #F9F4E1;
  background-image: url(../img/common/bg-message.webp);
  background-repeat: repeat;
  background-size: 100px;
}
@media screen and (min-width: 769px) {
  .top202403message {
    margin-top: 210px;
    padding-bottom: 55px;
  }
}
@media screen and (max-width: 768px) {
  .top202403message {
    margin-top: 100px;
    padding-bottom: 70px;
  }
}
.top202403message__deco {
  margin-inline: auto;
  position: relative;
  -webkit-transform: translateY(-92%);
  transform: translateY(-92%);
}
@media screen and (min-width: 769px) {
  .top202403message__deco {
    max-width: 1280px;
    width: 66.6666666667vw;
  }
}
@media screen and (max-width: 768px) {
  .top202403message__deco {
    width: 92.3076923077vw;
  }
}
.top202403message__deco > img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.top202403message .top202403message__slidearea {
  position: relative;
}
@media screen and (min-width: 769px) {
  .top202403message .top202403message__slidearea {
    padding-inline: min(100px, 5.2083333333vw);
  }
}
@media screen and (max-width: 768px) {
  .top202403message .top202403message__slidearea {
    padding-inline: 28px;
  }
}
@media screen and (min-width: 769px) {
  .top202403message .swiper {
    overflow: visible;
  }
}
@media screen and (min-width: 769px) {
  .top202403message .swiper-wrapper {
    display: grid;
    justify-content: center;
    gap: 60px;
    grid-template-columns: repeat(3, minmax(auto, 520px));
    overflow: visible;
  }
}
.top202403message .swiper-slide {
  position: relative;
}
@media screen and (min-width: 769px) {
  .top202403message .swiper-slide {
    overflow: visible;
    max-width: 520px;
  }
}
.top202403message .swiper-slide.--1st {
  --color: #eb6877;
}
.top202403message .swiper-slide.--2nd {
  --color: #80C269;
}
.top202403message .swiper-slide.--3rd {
  --color: #FFBF00;
}
@media screen and (min-width: 769px) {
  .top202403message .swiper-pagination,
  .top202403message .swiper-button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-pagination {
    top: inherit;
    bottom: -20px;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-pagination-bullet {
    width: 8px;
    background-color: rgba(235, 104, 119, 0);
    border: 1px solid #eb6877;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-pagination-bullet-active {
    background-color: #eb6877;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-button {
    width: 38px;
    height: 38px;
    background-color: #DDDDDD;
    color: #ffffff;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-button::after {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-button-prev {
    left: 0;
  }
}
@media screen and (max-width: 768px) {
  .top202403message .swiper-button-next {
    right: 0;
  }
}
.top202403message__titlearea {
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .top202403message__titlearea {
    position: absolute;
    bottom: -24px;
    left: -17px;
  }
}
@media screen and (max-width: 768px) {
  .top202403message__titlearea {
    position: relative;
    top: 1.5em;
  }
}
.top202403message__title {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  display: inline;
  padding: 0.7em 1.6em;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  line-height: 2.8;
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
    .top202403message__title {
        font-size: 1vw;
    }
}
@media screen and (min-width: 1460px) {
    .top202403message__title {
        font-size: 1.6rem;
    }
}

.top202403message__title > span {
  color: var(--color);
}
@media screen and (max-width: 768px) {
  .top202403message__img {
    display: block;
    padding-inline: 10px;
  }
}
.top202403message__img > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.top202403message__contarea {
  display: inline-block;
  background-color: #ffffff;
}
@media screen and (min-width: 769px) {
  .top202403message__contarea {
    padding: 1.5em;
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transform: translate(12px, 15px);
    transform: translate(12px, 15px);
  }
}
@media screen and (max-width: 768px) {
  .top202403message__contarea {
    display: block;
    padding: 0.8em 1.5em;
    min-width: 50%;
    max-width: 70%;
    margin-inline: auto 0;
    position: relative;
    -webkit-transform: translate(15px, -15%);
    transform: translate(15px, -15%);
  }
}
.top202403message__name {
  font-weight: 700;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 769px) {
  .top202403message__name {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403message__name {
    font-size: 1.6rem;
  }
}
.top202403message__name > span {
  display: inline-block;
  -webkit-margin-start: 0.5em;
  margin-inline-start: 0.5em;
}
@media screen and (min-width: 769px) {
  .top202403message__name > span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .top202403message__name > span {
    font-size: 1.3rem;
  }
}
.top202403message dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .top202403message dl:first-of-type {
    margin-top: 10px;
  }
}
.top202403message__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 300px;
  margin-inline: auto;
  padding: 1em !important;
}
@media screen and (min-width: 769px) {
  .top202403message__button {
    margin-top: 60px;
  }
}
@media screen and (max-width: 768px) {
  .top202403message__button {
    margin-top: 60px;
  }
}
.top202403message__button i {
  display: inline-block;
  -webkit-margin-start: 0.5em;
  margin-inline-start: 0.5em;
}

section.about.entry .btn01 {
  max-width: 300px;
  padding: 17px 10px;
  position: relative;
}
@media screen and (max-width: 768px) {
  section.about.entry .btn01 {
    margin-inline: auto;
  }
}
section.about.entry .btn01 > .icon-blank {
  display: inline-block;
  width: 1em;
  position: absolute;
  inset: auto 1.5em auto auto;
  margin: auto;
}

@media screen and (min-width: 769px) {
  #entry202403 {
    scroll-margin-block-start: 200px;
  }
}
@media screen and (max-width: 768px) {
  #entry202403 {
    scroll-margin-block-start: 40px;
  }
}

.entry202403 {
  width: 100svw;
  margin: 0 calc(50% - 50svw);
  background-color: #F9F4E1;
  background-image: url(../img/common/bg-message.webp);
  background-repeat: repeat;
  background-size: 100px;
  /* cont */
  /* titlearea */
  /* descarea */
}
@media screen and (min-width: 769px) {
  .entry202403 {
    padding-block: 86px 160px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403 {
    padding-block: 86px 80px;
  }
}
.entry202403 > .inner202403 {
  max-width: 1350px;
  width: 100%;
  margin-inline: auto;
}
@media screen and (max-width: 768px) {
  .entry202403 > .inner202403 {
    max-width: calc(100% - 15px - 15px);
  }
}
.entry202403 .hdg01 {
  margin-bottom: 32px;
}
.entry202403__cont {
  margin-inline: auto;
  background-color: #ffffff;
}
@media screen and (min-width: 769px) {
  .entry202403__cont {
    padding: 80px 15px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont {
    padding: 40px 15px;
  }
}
@media screen and (min-width: 769px) {
  .entry202403__cont:not(:nth-of-type(1)) {
    margin-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont:not(:nth-of-type(1)) {
    margin-top: 70px;
  }
}
.entry202403__cont:nth-of-type(1) .entry202403__cont__fc {
  color: #EB6877;
}
.entry202403__cont:nth-of-type(1) .entry202403__cont__bc {
  background-color: #FEEFF2;
}
.entry202403__cont:nth-of-type(2) .entry202403__cont__fc {
  color: #80C269;
}
.entry202403__cont:nth-of-type(2) .entry202403__cont__bc {
  background-color: #F0FAF0;
}
.entry202403__cont:nth-of-type(3) .entry202403__cont__fc {
  color: #FFBF00;
}
.entry202403__cont:nth-of-type(3) .entry202403__cont__bc {
  background-color: #FFF7E0;
}
.entry202403__cont:nth-of-type(3) .entry202403__cont__title__bc {
  background-color: #FFF7E0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, #FFF7E0));
  background: linear-gradient(transparent 60%, #FFF7E0 60%);
}
.entry202403__cont__title {
  display: inline;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 769px) {
  .entry202403__cont__title {
    padding: 0.5em 0.8em;
    font-size: 2.6rem;
    line-height: 2.2;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont__title {
    padding: 0.8em 1em;
    font-size: 1.6rem;
    line-height: 2.8;
    position: relative;
    top: 32px;
  }
}
@media screen and (min-width: 769px) {
  .entry202403__cont__thumbnail {
    aspect-ratio: 442/309;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont__thumbnail {
    width: calc(100% - 12px);
    aspect-ratio: 317/204;
    margin-inline: auto 0;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont__infoarea {
    padding-left: 12px;
  }
}
.entry202403__cont__name {
  font-weight: 700;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 769px) {
  .entry202403__cont__name {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont__name {
    font-size: 1.6rem;
  }
}
.entry202403__cont__name > span {
  display: inline-block;
  -webkit-margin-start: 0.5em;
  margin-inline-start: 0.5em;
}
@media screen and (min-width: 769px) {
  .entry202403__cont__name > span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont__name > span {
    font-size: 1.3rem;
  }
}
.entry202403__cont dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5em;
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .entry202403__cont dl {
    font-size: 1.5rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__cont dl {
    font-size: 1.2rem;
    line-height: 1.5833333333;
  }
}
@media screen and (min-width: 769px) {
  .entry202403__cont dl:nth-of-type(1) {
    margin-top: 20px;
  }
}
.entry202403__cont img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.entry202403__titlearea {
  display: -ms-grid;
  display: grid;
  margin-inline: auto;
}
@media screen and (min-width: 769px) {
  .entry202403__titlearea {
    -ms-grid-columns: 44.2% 58px 1fr;
    grid-template-columns: 44.2% 1fr;
    -ms-grid-rows: auto 30px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "img title" "img desc";
    gap: 30px 58px;
    max-width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__titlearea {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 12px auto 12px auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "title" "img" "desc";
    gap: 12px;
    max-width: 100%;
    margin-top: -32px;
  }
}
.entry202403__titlearea .entry202403__cont__titlearea {
  grid-area: title;
  position: relative;
  z-index: 10;
}
.entry202403__titlearea .entry202403__cont__thumbnail {
  grid-area: img;
}
.entry202403__titlearea .entry202403__cont__infoarea {
  grid-area: desc;
}
@media screen and (min-width: 769px){
  .entry202403__titlearea .entry202403__cont__titlearea {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .entry202403__titlearea .entry202403__cont__thumbnail {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
  }
  .entry202403__titlearea .entry202403__cont__infoarea {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
}
@media screen and (max-width: 768px){
  .entry202403__titlearea .entry202403__cont__titlearea {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .entry202403__titlearea .entry202403__cont__thumbnail {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }
  .entry202403__titlearea .entry202403__cont__infoarea {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
}
.entry202403__descarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 30px;
  margin-inline: auto;
}
@media screen and (min-width: 769px) {
  .entry202403__descarea {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1000px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__descarea {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .entry202403__descarea:nth-of-type(even) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
.entry202403__desc__title {
  display: block;
  width: 100%;
  min-height: 3em;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.6666666667;
  position: relative;
}
@media screen and (min-width: 769px) {
  .entry202403__desc__title {
    margin-top: 120px;
    padding: 0.4em 2.4em 0.4em 5em;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__desc__title {
    margin-top: 70px;
    padding: 0.4em 1em 0.4em 4em;
  }
}
.entry202403__desc__title__icon {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 769px) {
  .entry202403__desc__title__icon {
    font-size: 6.3rem;
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__desc__title__icon {
    font-size: 5rem;
    -webkit-transform: translateY(-45%);
    transform: translateY(-45%);
    left: 5px;
  }
}
.entry202403__desc__img {
  aspect-ratio: 363.64/242.45;
}
@media screen and (min-width: 769px) {
  .entry202403__desc__img {
    width: 36%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .entry202403__desc__img {
    width: 100%;
  }
}
.entry202403__desc {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.entry202403__desc > p {
  margin-top: 24px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.875;
}

.login202403 {
  width: 100%;
  background-color: #FEEFF2;
}
.login202403__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (min-width: 769px) {
  .login202403__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-block: 3.125vw;
  }
}
@media screen and (max-width: 768px) {
  .login202403__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-inline: 15px;
  }
}
.login202403__cont {
  text-align: center;
}
@media screen and (min-width: 769px) {
  .login202403__cont {
    width: 50%;
    padding: 3.125vw 4.1666666667vw;
  }
}
@media screen and (max-width: 768px) {
  .login202403__cont {
    padding-block: 40px;
  }
}
@media screen and (min-width: 769px) {
  .login202403__cont:nth-of-type(1) {
    padding-block: 0;
    border-right: 1px solid #C3C3C3;
  }
}
@media screen and (max-width: 768px) {
  .login202403__cont:nth-of-type(1) {
    border-bottom: 1px solid #C3C3C3;
  }
}
.login202403__cont:nth-of-type(1) .btn02 {
  margin-top: 30px;
}
.login202403__cont:nth-of-type(2) .btn02 {
  margin-top: 25px;
}
@media screen and (min-width: 769px) {
  .login202403__cont:nth-of-type(2) article:nth-of-type(2) {
    margin-top: 75px;
  }
}
@media screen and (max-width: 768px) {
  .login202403__cont:nth-of-type(2) article:nth-of-type(2) {
    margin-top: 40px;
  }
}
.login202403 p {
  margin-top: 30px;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.625;
}
.login202403__title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.login202403 .btn01,
.login202403 .btn02 {
  width: min(100%, 300px);
  margin-inline: auto;
}
.login202403 .btn02 {
  position: relative;
}
.login202403 .btn02 > img {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.login202403 .btn02 i.fa-arrow-right {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.login202403 .swpm-login-form-inner {
  padding: 0;
}
.login202403 .swpm-username-input {
  margin-bottom: 30px;
}
.login202403 .swpm-password-input {
  margin-bottom: 0px;
}
.login202403 .swpm-username-input input,
.login202403 .swpm-password-input input {
  padding-left: 10px;
  padding: 1em 1.5em;
}
.login202403 .swpm-username-input:before,
.login202403 .swpm-password-input:before {
  display: none;
}
.login202403 .swpm-text-field.swpm-username-field,
.login202403 .swpm-text-field.swpm-password-field {
  max-width: 525px;
}
.login202403 .swpm-login-form-submit {
  width: min(100%, 300px);
  margin-inline: auto;
  background-color: #eb6877;
  background-image: url(../img/common/arrow01.png);
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: auto;
}
@media screen and (min-width: 769px) {
  .login202403 .swpm-login-form-submit {
    margin-top: 55px;
  }
}
@media screen and (max-width: 768px) {
  .login202403 .swpm-login-form-submit {
    margin-top: 38px;
  }
}
.login202403 .swpm-login-submit {
  margin-bottom: 0;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 1.4rem;
  color: #332c2b;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  height: 100%;
  line-height: 1.6;
  width: 100%;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media screen and (min-width: 769px) {
  a:hover {
    text-decoration: none;
    opacity: 0.65;
  }
}
@media screen and (min-width: 769px) {
  a:active, a:hover {
    outline-width: 0;
  }
}

li {
  list-style: none;
}

figcaption, figure, main, article, aside, footer, header, nav, section, .section {
  display: block;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

small {
  font-size: 80%;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: none;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  border-style: none;
}

button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: none;
  outline-offset: -2px;
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: none;
  font: inherit;
}

canvas {
  display: inline-block;
}

iframe {
  vertical-align: middle;
}

/* form input*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

input[type=text], input[type=tel], input[type=url], input[type=password], input[type=number] {
  font-size: 14px;
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  outline: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  vertical-align: middle;
  height: 45px;
}

select, textarea {
  font-size: 14px;
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  outline: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  vertical-align: middle;
  height: 45px;
}

input[type=text]:focus, input[type=email]:focus, input[type=tel]:focus, input[type=url]:focus, input[type=password]:focus, input[type=number]:focus {
  border-color: #ec6d74;
}

select:focus, textarea:focus {
  border-color: #ec6d74;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  font-size: 14px;
  font-size: 1.4rem;
  height: 2.5em;
  padding: 0.5em;
  width: 180px;
  height: 45px;
  min-width: 7em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.04em;
  color: #999999;
  padding: 10px 15px;
  background-image: url(../img/common/select.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 9px;
  position: relative;
}

input[type=email] {
  font-size: 14px;
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  outline: none;
  padding: 10px;
  width: 100%;
  max-width: 520px;
  vertical-align: middle;
}

textarea {
  font-size: 14px;
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  outline: none;
  padding: 10px;
  width: 100%;
  max-width: 520px;
  vertical-align: middle;
  height: 200px;
}

input[type=checkbox], input[type=radio] {
  background: #fff;
  cursor: pointer;
  margin-right: 0.5em;
}

/* Grid System width Flex */
.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flex-row {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-row-rev {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-col {
  -webkit-box-orient: stheimkpiloartie;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: col;
  flex-direction: col;
}

.flex-col-rev {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: col-reverse;
  flex-direction: col-reverse;
}

.flex-nowrap {
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.flex-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/*margin*/
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

/* wordpress wp-pagenavi */
.pagenavi-area {
  margin-bottom: 100px;
}
.pagenavi-area .wp-pagenavi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.pagenavi-area .wp-pagenavi a {
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 100%;
  border: 1px solid #dddddd;
  color: #cccccc;
  margin: 3px;
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 0;
}
.pagenavi-area .wp-pagenavi span {
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 100%;
  color: #eb6877;
  margin: 3px;
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 0;
}
.pagenavi-area .wp-pagenavi span.current, .pagenavi-area .wp-pagenavi span a.current {
  border: 0px;
  color: #fff;
  background: #eb6877;
}
.pagenavi-area .wp-pagenavi a:hover {
  border: 1px solid #eb6877;
}
.pagenavi-area .wp-pagenavi a.prevpostslink {
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 1.2em;
  background: url(../img/common/btn-pagenavi-prev.png) no-repeat center center/contain;
  background-size: 16px 16px;
  border: none;
}
.pagenavi-area .wp-pagenavi a.nextpostslink {
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 1.2em;
  background: url(../img/common/btn-pagenavi-next.png) no-repeat center center/contain;
  background-size: 16px 16px;
  border: none;
}
.pagenavi-area.green-box .wp-pagenavi span {
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 100%;
  color: #7fc168;
  margin: 3px;
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 0;
}
.pagenavi-area.green-box .wp-pagenavi span.current {
  border: 0px;
  color: #fff;
  background: #7fc168;
  font-weight: normal;
}
.pagenavi-area.green-box .wp-pagenavi span a.current {
  border: 0px;
  color: #fff;
  background: #7fc168;
}
.pagenavi-area.green-box .wp-pagenavi a:hover {
  border: 1px solid #39b8b8;
}
.pagenavi-area.green-box .wp-pagenavi a.nextpostslink {
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 1.2em;
  background: url(../img/common/btn-pagenavi-next-green.png) no-repeat center center/contain;
  background-size: 16px 16px;
  border: none;
}

@media screen and (max-width: 375px) {
  .wp-pagenavi a, .wp-pagenavi span {
    width: 2.4em;
    height: 2.4em;
    line-height: 2.4;
  }
  .wp-pagenavi a.prevpostslink {
    width: 2.4em;
    height: 2.4em;
    line-height: 2.4;
  }
  .wp-pagenavi a.nextpostslink {
    width: 2.4em;
    height: 2.4em;
    line-height: 324;
  }
}
/*.wp-pagenavi a.previouspostslink,
.wp-pagenavi a.nextpostslink{
  position: relative;
}

.wp-pagenavi a.previouspostslink::after,
.wp-pagenavi a.nextpostslink::after{
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  left: 50%;
  top: 50%;
  margin-top: -2px;
  margin-left: -4px;
  transform: rotate(45deg);
  transition: all .3s;
}

.wp-pagenavi a.previouspostslink::after{
  background: url(../img/common/btn-pagenavi-next.png) no-repeat center center / contain;
  background-size: 16px 16px;
}

.wp-pagenavi a.nextpostslink::after{
  background: url(../img/common/btn-pagenavi-next.png) no-repeat center center / contain;
  background-size: 16px 16px;
}

.wp-pagenavi a.previouspostslink:hover::after,
.wp-pagenavi a.nextpostslink:hover::after{
  border-color: #FFF;
}*/
.wp-caption-text, .wp-caption, .aligncenter, .alignleft, .alignright, .sticky, .gallery-caption, .bypostauthor, .screen-reader-text {
  font-weight: normal;
}

/* toggle button */
.toggle {
  display: none;
}

@media screen and (max-width: 1200px) {
  .toggle {
    display: block;
    position: fixed;
    width: 60px;
    height: 60px;
    z-index: 999;
    right: 0;
    top: 0;
    background-color: #eb6877;
    border-radius: 0 0 0 5px;
  }
  .toggle span {
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #fff;
    left: 15px;
    top: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .toggle span:first-child {
    margin-top: -10px;
  }
  .toggle span:nth-child(3) {
    margin-top: 10px;
  }
  .toggle::after {
    content: "メニュー";
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
  }
  .toggle.active::after {
    content: "とじる";
  }
  .toggle.active span:first-child {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .toggle.active span:nth-child(2) {
    opacity: 0;
  }
  .toggle.active span:nth-child(3) {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
@media screen and (min-width: 1201px) {
  .gnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
}
@media screen and (max-width: 1200px) {
  .gnav {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    height: 100vh;
    top: 0;
    right: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
  }
  .gnav.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/*////////////////////////

 user common css

////////////////////////*/
.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container-l {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1380px;
}

.f-en {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.bold {
  font-weight: bold;
}

.t-under {
  text-decoration: underline !important;
}

.pink {
  color: #eb6777;
}

.green {
  color: #80c269;
}

.red {
  color: #ff0101;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.fz12 {
  font-size: 1.2rem;
}

.fz13 {
  font-size: 1.3rem;
}

.fz15 {
  font-size: 1.5rem;
}

.fz16 {
  font-size: 1.6rem;
}

.fz22 {
  font-size: 2.2rem;
}

.tel {
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}
.tel i {
  font-size: 0.6em;
  color: #eb6877;
  margin-right: 5px;
  vertical-align: 2px;
}

.hdg01 {
  font-size: 3.2rem;
  text-align: center;
  line-height: 1.4;
}
.hdg01 span {
  display: block;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.1rem;
}

[class^=link01] {
  display: block;
  font-size: 1.5rem;
  padding-left: 20px;
  position: relative;
}
[class^=link01]::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ddd;
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 1;
}
[class^=link01]::after {
  content: "";
  width: 4px;
  height: 4px;
  border-top: 1px solid #7a6765;
  border-right: 1px solid #7a6765;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 5px;
  top: 7px;
  z-index: 2;
}

.link01--pink::before {
  background-color: #eb6877;
}

.link01--green::before {
  background-color: #80c269;
}

.link01--pink::after, .link01--green::after {
  border-color: #fff;
}

.btn01 {
  display: block;
  padding: 10px;
  background-color: #eb6877;
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
  min-width: 170px;
  text-align: center;
}
.btn01 img {
  margin-right: 5px;
  vertical-align: baseline;
}
.btn01.arrow {
  background-image: url(../img/common/arrow01.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.btn02 {
  display: block;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  color: #eb6877;
  border: 1px solid #eb6877;
  background-color: #fff;
  padding: 12px;
  border-radius: 30px;
  min-width: 250px;
}

.btn-login {
  display: block;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(25deg, #e88604, #feb410);
  padding: 13px;
  border-radius: 30px;
  width: 100%;
  border: none;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.btn-login i {
  font-size: 1.4rem;
}
.btn-login:hover {
  opacity: 0.7;
}

.btn-A {
  display: block;
  max-width: 205px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/arrow01-pink.png) no-repeat center right 30px/contain;
  background-size: 10px 11px;
  padding: 15px 40px;
}

.btn-B {
  display: block;
  max-width: 350px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/icn-download.png) no-repeat center right 30px/contain;
  background-size: 14px 12px;
  padding: 15px 40px;
}

.btn-C {
  display: block;
  max-width: 205px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/icn-window-pink.png) no-repeat center right 30px/contain;
  background-size: 10px 9px;
  padding: 15px 40px;
}

.btn-D {
  display: block;
  max-width: 280px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/arrow01-pink.png) no-repeat center right 30px/contain;
  background-size: 10px 11px;
  padding: 15px 40px;
}

.btn-pdf {
  display: block;
  max-width: 500px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/icn-download-green.png) no-repeat center right 30px/contain;
  background-size: 14px 12px;
  padding: 15px 45px 15px 40px;
}
.btn-pdf img {
  margin-right: 5px;
}

.sp, .sp02 {
  display: none;
}

.gmap {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 70px;
}
.gmap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 769px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  }
}
@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
}
@media screen and (max-width: 550px) {
  .pc02 {
    display: none;
  }
  .sp02 {
    display: block;
  }
}
/*////////////////////////

 header

////////////////////////*/
.header .h-logo {
  width: 28%;
}
.header .gnav {
  width: 70%;
  white-space: nowrap;
}
.header .gnav .gnav-box {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.header .gnav .gnav-box ul:not(.fz-change) li {
  margin-right: 10px;
}
.header .gnav .gnav-box ul:not(.fz-change) li .link01 {
  font-size: 1.3rem;
  padding-left: 17px;
}
.header .gnav .gnav-box ul:not(.fz-change) li .link01::before {
  width: 13px;
  height: 13px;
}
.header .gnav .gnav-box ul:not(.fz-change) li .link01::after {
  width: 3px;
  height: 3px;
  left: 4px;
  top: 6px;
}
.header .gnav .gnav-box .tel {
  font-size: 3rem;
}
.header .gnav .fz-change {
  font-size: 1.3rem;
}
.header .gnav .fz-change li:not(.bold) {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  text-align: center;
  line-height: 22px;
  color: #c3c3c3;
  cursor: pointer;
  margin-left: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header .gnav .fz-change li:not(.bold):hover {
  border-color: #eb6877;
  color: #eb6877;
}
.header .gnav .fz-change li.active {
  background-color: #eb6877;
  border-color: #eb6877;
  color: #fff !important;
}

.search-box .search-form {
  position: relative;
  width: 250px;
}
.search-box .search-form__txt {
  padding: 10px;
  border-radius: 5px;
  background: #f8f8f8;
  border-color: #e2e2e2;
  font-size: 1.3rem;
}
.search-box .search-form__btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  color: #bbb;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  font-size: 1.6rem;
}
.search-box .search-form__btn:hover {
  color: #eb6877;
}

.header .gnav .nav-list {
  padding: 15px 0;
  width: 100%;
}
.header .gnav .nav-list .nav-list__item {
  position: relative;
}
.header .gnav .nav-list .nav-list__item:not(:last-child):not(:first-child) {
  border-left: 1px solid #ddd;
}
.header .gnav .nav-list .nav-list__item > a {
  font-size: 1.6rem;
  font-weight: bold;
  padding-right: 12px;
}
.header .gnav .nav-list .nav-list__item:not(:first-child) > a {
  padding-left: 12px;
}
.header .gnav .nav-list .nav-list__item > a:hover {
  opacity: 1;
  color: #eb6877;
}
.header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list__item {
  margin-bottom: 10px;
}
.header .gnav .nav-list .nav-list__item .sub-menu a {
  font-size: 1.4rem;
  padding-left: 16px;
}
.header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list__item .link01--pink::before {
  width: 13px;
  height: 13px;
}
.header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list__item .link01--pink::after {
  width: 3px;
  height: 3px;
  left: 4px;
}

@media screen and (max-width: 1350px) {
  .header .h-logo {
    width: 230px;
  }
  .header .gnav {
    width: calc(100% - 250px) !important;
    max-width: 945px;
  }
}
@media screen and (min-width: 1201px) {
  .header .gnav .nav-list .nav-list__item .sub-menu {
    position: absolute;
    z-index: 10;
    width: 690px;
    background-color: #fff;
    border: 1px solid #eb6877;
    border-radius: 15px;
    padding: 40px 20px 20px;
    left: 50%;
    top: 60px;
    margin-left: -345px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: none;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 17px 7px;
    border-color: transparent transparent #eb6877 transparent;
    position: absolute;
    left: 50%;
    top: -17px;
    margin-left: -7px;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list__item .link01--pink::before {
    width: 13px;
    height: 13px;
    top: 4px;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list__item .link01--pink::after {
    width: 3px;
    height: 3px;
    top: 8px;
    left: 4px;
  }
}
@media screen and (max-width: 1200px) {
  .header {
    height: auto;
  }
  .header .h-logo {
    padding: 10px 0;
  }
  .header .gnav {
    width: 100%;
    max-width: 290px;
    padding: 0 20px;
  }
  .header .gnav .gnav-box.flexbox {
    display: block;
    border-bottom: none;
  }
  .header .gnav .gnav-box ul:not(.fz-change) {
    display: block;
  }
  .header .gnav .gnav-box ul:not(.fz-change) li {
    margin: 0 0 10px;
  }
  .header .gnav .gnav-box .fz-change {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px 0 20px;
  }
  .header .gnav .gnav-box .fz-change .bold {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
  .header .gnav .fz-change li:not(.bold) {
    margin: 0 5px;
  }
  .header .gnav .nav-list {
    width: 100%;
    display: block !important;
    margin: 5px 0 0 0;
    padding: 60px 0 20px;
    text-align: center;
  }
  .header .gnav .nav-list .nav-list__item {
    padding: 15px 0;
    line-height: 1.2;
    border: none !important;
  }
  .header .gnav .nav-list .nav-list__contact {
    margin-top: 10px;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu {
    text-align: left;
    width: 210px;
    margin: 0 auto;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list {
    margin-top: 20px;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list .bold {
    position: relative;
  }
  .header .gnav .nav-list .nav-list__item .sub-menu .sub-menu__list .bold::before {
    content: "-";
    position: absolute;
    left: 0;
    top: 0;
  }
}
/*////////////////////////

footer

////////////////////////*/
.footer .f-search {
  padding: 30px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fcfcfc;
}
.footer .f-search .search-box .bold {
  margin-right: 10px;
}
.footer .f-search .search-box .search-form {
  width: 380px;
}
.footer .f-search .search-box .search-form__txt {
  background-color: #fff;
}
.footer .f-search ul.flexbox li {
  margin-left: 10px;
}
.footer .f-search ul.flexbox li .btn-login {
  min-width: 250px;
}
.footer .f-nav {
  padding: 50px 15px 40px;
}
.footer .f-nav .add {
  line-height: 1.8;
}
.footer .f-nav .add a {
  color: #7fc067;
  text-decoration: underline;
}
.footer .f-logo {
  margin-bottom: 20px;
}
.footer .add .bnr-box {
  margin-top: 70px;
}
.footer .add .bnr-box .bold {
  font-size: 1.3rem;
  margin-bottom: 10px;
  padding-left: 12px;
  position: relative;
}
.footer .add .bnr-box .bold::before {
  content: "";
  width: 8px;
  height: 2px;
  background-color: #eb6877;
  left: 0;
  top: 10px;
  position: absolute;
}
.footer .add .bnr-box .bold img {
  margin-left: 2px;
  vertical-align: 3px;
}
.footer .add .bnr-box ul li {
  margin-bottom: 7px;
}
.footer .sub-nav {
  width: calc(100% - 280px);
  max-width: 1005px;
}
.footer .sub-nav .sub-nav__cont:first-of-type {
  padding-bottom: 10px;
  margin-bottom: 50px;
  border-bottom: 1px solid #ddd;
}
.footer .sub-nav .col01 {
  min-width: 160px;
}
.footer .sub-nav .col02 .sub-nav__list {
  padding-right: 20px;
}
.footer .sub-nav [class^=link01] {
  font-weight: bold;
  padding-left: 16px;
}
.footer .sub-nav [class^=link01]::before {
  width: 13px;
  height: 13px;
  top: 5px;
}
.footer .sub-nav [class^=link01]::after {
  width: 3px;
  height: 3px;
  left: 4px;
  top: 9px;
}
.footer .sub-nav .sub-nav__list {
  margin-bottom: 30px;
}
.footer .sub-nav .sub-nav__list .sub-nav__list__item {
  margin-bottom: 8px;
}
.footer .sub-nav a:not(.link01--pink):not(.link01--green) {
  font-size: 1.3rem;
  padding-left: 15px;
  position: relative;
}
.footer .sub-nav .sub-nav__list .sub-nav__list__item a:not(.link01--pink):not(.link01--green)::before {
  content: "-";
  left: 5px;
  top: 0;
  position: absolute;
}
.footer .sub-nav .col02 .sub-nav__list .sub-nav__list__item:first-child a {
  padding-left: 0;
}
.footer .sub-nav .col02 .sub-nav__list .sub-nav__list__item:first-child a::before {
  content: normal !important;
}
.footer .f-copy {
  border-top: 1px solid #ddd;
  background-color: #fafafa;
  padding: 30px 0;
  line-height: 1.2;
}
.footer .f-copy .flexbox li {
  border-left: 1px solid #a7a7a7;
}
.footer .f-copy .flexbox li a {
  font-size: 1.3rem;
  display: block;
  padding: 0 15px;
  color: #a7a7a7;
}
.footer .f-copy .flexbox li:last-child {
  border-right: 1px solid #a7a7a7;
}

@media screen and (max-width: 1100px) {
  .footer .f-search {
    padding: 20px 0;
  }
  .footer .f-search .search-box {
    width: 100%;
  }
  .footer .f-search .search-box .search-form {
    width: calc(100% - 95px);
  }
  .footer .f-search ul.flexbox {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .footer .f-search ul.flexbox li {
    margin: 15px 0 0;
    width: 49%;
  }
  .footer > .flexbox {
    width: 100%;
    max-width: 700px;
    display: block;
  }
  .footer .add {
    width: 100%;
    padding: 0 0 30px 0;
  }
  .footer .sub-nav {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer .f-search ul.flexbox {
    display: block;
  }
  .footer .f-search ul.flexbox li {
    width: 100%;
  }
  .footer .sub-nav .sub-nav__list {
    width: 100%;
  }
  .footer .f-copy .copy {
    width: 100%;
    margin-top: 10px;
  }
}
/* top */
.main-wrap {
  position: relative;
}

.main-visual {
  background: url(../img/top/main-visual.jpg) no-repeat center center/cover;
  min-height: 580px;
  padding: 95px 0 20%;
  position: relative;
  z-index: -1;
}

.login {
  max-width: 300px;
}
.login .login__box {
  border: 2px solid #eb6877;
  background-color: #fff;
  border-radius: 10px;
}
.login .login__box .login__box__hdg {
  text-align: center;
  padding: 10px;
  font-size: 2rem;
  color: #fff;
  background-color: #eb6877;
  border-radius: 7px 7px 0 0;
}
.login .login__box .login__box__hdg i {
  font-size: 0.8em;
  vertical-align: 1px;
}
.login .login__box .inner {
  padding: 5px 25px 20px;
}
.login .login__box dl {
  position: relative;
}
.login .login__box dl dt {
  position: absolute;
  width: 50px;
  background-color: #dedede;
  border-radius: 4px 0 0 4px;
  padding: 6px 5px;
  left: 1px;
  top: 1px;
}
.login .login__box dl dd input {
  width: 100%;
  border-radius: 5px;
  padding: 8px 8px 8px 55px;
  background-color: #f8f8f8;
  height: 34px;
}
.login .login__box .btn-login {
  margin-bottom: 15px;
  position: relative;
  background-image: url(../img/common/arrow01.png), url(../img/common/login-bg.jpg);
  background-repeat: no-repeat;
  background-position: right 15px center, center center;
  background-size: auto, cover;
}
.login .btn02 {
  margin-top: 20px;
  border-radius: 10px;
  /*font-size: 1.8rem;*/
  font-size: 1.6rem;
  padding: 15px 0;
}

.main-visual .main-visual__hdg {
  padding-left: 100px;
}

.top-news {
  margin-top: -60px;
}
.top-news .top-news__box {
  width: 49%;
  padding: 40px 50px 30px;
  border-top: 2px solid;
  margin-bottom: 30px;
}
.top-news .top-news__box .top-news-hdg {
  border-bottom: 1px dotted #bbb;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.top-news .top-news__box .top-news__box__hdg {
  font-size: 2.4rem;
}
.top-news .top-news__box .top-news__box__hdg img {
  vertical-align: bottom;
  margin-right: 10px;
}
.top-news .top-news__box .top-news__box__hdg span {
  vertical-align: 5px;
  margin-left: 10px;
}

.top-news__box.training {
  background-color: #fcf5f5;
  border-color: #eb6877;
}
.top-news__box.information {
  background-color: #f7f9f7;
  border-color: #80c269;
}

.news-list .news-list__item {
  margin-bottom: 10px;
}
.news-list .news-list__item a {
    display: grid;
    grid-template-columns: 80px 80px 1fr;
    align-items: center;
    gap: 1em;
  padding-right: 85px;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right 12px center;
  min-height: 43px;
}
.news-list .news-list__item a::before {
  content: "";
  width: 1px;
  height: 100%;
  border-left: 1px dotted #dfdfdf;
  position: absolute;
  top: 0;
  right: 40px;
}
.news-list .news-list__item.new a::after {
  content: "NEW!";
  color: #ffbf00;
  font-size: 1.3rem;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-family: "Roboto", sans-serif;
}
.news-list .news-list__item a .cate {
  width: 100%;
  text-align: center;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 44px;
  padding: 0 2px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5em 0;
}
.news-list .news-list__item a .date {
  padding: 2px 10px 1px;
  border-radius: 15px;
  color: #bf9596;
  font-weight: 400;
  display: inline-block;
}
.news-list .news-list__item a .news-list__item__hdg {
  font-weight: normal;
  font-size: 1.4rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.news-list.training .news-list__item a {
  background-image: url(../img/common/arrow02-pink.png);
}
.news-list.information .news-list__item a {
  background-image: url(../img/common/arrow02-pink.png);
}
.news-list.training .news-list__item a .cate {
  background-color: #eb6877;
}
.news-list.information .news-list__item a .cate {
  background-color: #eb6877;
}
.news-list.training .news-list__item a .date {
  background-color: #faf0f0;
}
.news-list.information .news-list__item a .date {
  background-color: #f7f9f7;
}
.news-list.training .news-list__item a:hover .news-list__item__hdg {
  color: #eb6877;
}
.news-list.information .news-list__item a:hover .news-list__item__hdg {
  color: #eb6877;
}

.top-about {
  padding-top: 90px;
  background-image: url(../img/top/bg01.png), url(../img/top/bg02.png);
  background-repeat: no-repeat;
  background-position: 68% 150px, 30% 20%;
}
.top-about .container-l {
  padding-bottom: 110px;
  position: relative;
  background-image: url(../img/top/bg03.png), url(../img/top/bg04.png);
  background-repeat: no-repeat;
  background-position: 50px bottom, right 50px bottom;
}
.top-about .container-l::after {
  content: "";
  width: calc(100% - 30px);
  height: 15px;
  background: url(../img/common/bg01.jpg) repeat left top;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: -1;
}
.top-about .bold {
  line-height: 2.2;
  max-width: 520px;
  margin: 50px auto 30px;
}
.top-about .btn01 {
  max-width: 300px;
  font-size: 1.6rem;
  padding: 13px;
  margin: 0 auto 45px;
}

.top-intro .container-l {
  padding: 50px 15px;
  position: relative;
}
.top-intro .container-l::before {
  content: "";
  width: calc(100% - 30px);
  height: 72%;
  background: url(../img/common/bg01.jpg) repeat left top;
  position: absolute;
  left: 15px;
  top: 0;
  z-index: -1;
}
.top-intro .committee {
  width: 32%;
  max-width: 380px;
  margin-bottom: 50px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
}
.top-intro .committee .committee__hdg {
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  font-size: 2rem;
  font-weight: bold;
  padding: 15px 5px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  right: 30px;
  top: -20px;
}
.top-intro .committee img {
  border-radius: 10px 10px 0 0;
}
.top-intro .committee .committee-list {
  padding: 10px 15px 5px;
  max-width: 340px;
  margin: 0 auto;
}
.top-intro .committee .committee-list .committee-list__item {
  padding: 15px 10px;
  border-bottom: 1px dotted #ababab;
}
.top-intro .committee .committee-list .committee-list__item .link01--pink::before {
  top: 4px;
}
.top-intro .committee .committee-list .committee-list__item .link01--pink::after {
  top: 9px;
}
.top-intro .committee:nth-of-type(2) .committee-list .committee-list__item:last-child {
  border-bottom: none;
}
.top-intro .top-intro__link {
  max-width: 1050px;
  margin: 0 auto;
}
.top-intro .top-intro__link .committee02 {
  width: 48%;
  margin-bottom: 20px;
}
.top-intro .top-intro__link .committee02 a {
  display: block;
}
.top-intro .top-intro__link .committee02 .link01--pink {
  font-size: 2rem;
  padding-left: 25px;
  margin: 20px 0 10px;
}
.top-intro .top-intro__link .committee02 .link01--pink::before {
  width: 20px;
  height: 20px;
  top: 5px;
}
.top-intro .top-intro__link .committee02 .link01--pink::after {
  width: 5px;
  height: 5px;
  top: 12px;
  left: 6px;
}
.top-intro .top-intro__link .committee02 p {
  line-height: 2;
}

.top-other {
  padding: 65px 0 60px;
  background: url(../img/top/bg05.jpg) no-repeat center center/cover;
}
.top-other .container-l {
  max-width: 1280px;
}
.top-other .other-contents {
  /*width: 24%;*/
  width: 32%;
  margin-bottom: 12px;
}
.top-other .other-contents a {
  display: block;
  border-radius: 10px;
  border: 1px solid #80c269;
  background-color: #fff;
  height: 100%;
}
.top-other .other-contents .other-contents_hdg {
  padding: 20px 15px 15px;
  background-color: #f5fcf5;
  border-radius: 9px 9px 0 0;
}
.top-other .other-contents .other-contents_hdg .link01--green {
  font-size: 1.6rem;
}
.top-other .other-contents .other-contents_hdg .link01--green::before {
  top: 3px;
}
.top-other .other-contents .other-contents_hdg .link01--green::after {
  top: 8px;
}
.top-other .other-contents p {
  padding: 10px 15px 15px;
}

.f-contact {
  padding: 60px 0;
}
.f-contact .contact-box {
  padding: 60px 15px;
  background-color: #feeff2;
  border-radius: 10px;
}
.f-contact .contact-box ul {
  max-width: 680px;
  margin: 0 auto;
}
.f-contact .contact-box li {
  width: 50%;
}
.f-contact .contact-box .tel {
  font-size: 4.2rem;
  font-weight: 500;
}
.f-contact .contact-box .btn01 {
  font-size: 1.8rem;
  padding: 17px 10px;
}

@media screen and (max-width: 1000px) {
  .main-visual .main-visual__hdg {
    padding-left: 0;
  }
  .top-news .top-news__box {
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .main-wrap .login-wrap {
    position: absolute;
    max-width: 1380px;
    width: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 100%;
  }
  .main-wrap .login-wrap .login {
    position: absolute;
    right: 15px;
    top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .main-visual {
    background-size: 100%;
    background-position: center bottom;
    background-color: #daf5ff;
    min-height: auto;
    padding: 20% 0 25%;
  }
  .main-visual .container-l .login {
    margin: 50px auto 0;
  }
  .main-wrap .login-wrap .login {
    margin: 30px auto;
  }
  .top-news {
    margin-top: 0;
  }
  .top-news .top-news__box {
    padding: 30px 15px;
  }
  .top-news .top-news__box .top-news__box__hdg span {
    display: block;
    padding-left: 45px;
  }
  .top-about .container-l {
    background-size: 45%;
    background-position: 20px bottom, right 20px bottom;
  }
  .top-intro .committee {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .top-intro .committee .committee-list .committee-list__item:last-child {
    border-bottom: none;
  }
  .top-intro .top-intro__link .committee02 {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .top-other .other-contents {
    width: 49%;
  }
  .f-contact .contact-box {
    padding: 30px 15px;
  }
  .f-contact .contact-box li {
    width: 100%;
  }
  .f-contact .contact-box li:first-child {
    text-align: center;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 550px) {
  .top-news .top-news__box .top-news__box__hdg {
    width: 100%;
  }
  .top-news .top-news__box .top-news-hdg .fz15 {
    display: block;
    margin: 10px 0 0 auto;
  }
  .news-list .news-list__item a.flexbox {
    display: block;
    padding-right: 50px;
  }
  .news-list .news-list__item a .news-list__item__hdg {
    margin-top: 10px;
  }
  .top-other .other-contents {
    width: 100%;
  }
  .f-contact .contact-box .tel {
    font-size: 3rem;
  }
}
/* 210222 追記 */
.banner-item {
  margin: 0 22.5px;
}

@media screen and (max-width: 797px) {
  .banner-item:first-child {
    margin-bottom: 20px;
  }
}
/*--------------------
      subpage
---------------------*/
.subpage {
  background: url(../img/about/about-main.jpg) no-repeat center center/cover;
  padding: 75px 0 65px;
  text-align: center;
}
.subpage.member {
  background: url(../img/member/member-main.jpg) no-repeat center center/cover;
  padding: 75px 0 65px;
  text-align: center;
}

.subpage_headding {
  font-size: 3.2rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
  font-weight: bold;
}
.subpage_headding span {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  color: #eb6677;
  font-weight: 700;
}

.subpage.member .subpage_headding span {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  color: #fdb10f;
  font-weight: 700;
}
.subpage.green-box .subpage_headding span {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  color: #7fc168;
  font-weight: 700;
}

/*--------------------
      about
---------------------*/
.about {
  width: 100%;
}

.breadcrumbs {
  width: 100%;
  padding-top: 20px;
  margin-bottom: 80px;
}
.breadcrumbs ul li {
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  line-height: 2;
  margin-right: 35px;
  position: relative;
}
.breadcrumbs ul li:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 1px;
  top: calc(50% - 3px);
  right: -20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #d2cccc;
}
.breadcrumbs ul li:after {
  position: absolute;
  content: "";
  width: 6px;
  height: 1px;
  bottom: calc(50% - 2px);
  right: -20px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  background: #d2cccc;
}
.breadcrumbs ul li:last-child:before, .breadcrumbs ul li:last-child:after {
  display: none;
}
.breadcrumbs ul li a {
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  line-height: 2;
  color: #eb6677;
}
.breadcrumbs ul li .home .fa-home {
  margin-right: 5px;
}
.breadcrumbs ul li a span {
  border-bottom: 1px solid #eb6677;
}

.main-content {
  max-width: 930px;
  width: 100%;
}

.about_hdg__headding {
  font-size: 3.6rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
  font-weight: bold;
  color: #eb6777;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
    .about_hdg__headding {
        font-size: 2.8rem;
    }
}

.about_hdg__headding.green-box {
  color: #7fc168;
}

.about_under__link-box {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.about_under__link {
  display: block;
  max-width: 280px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  padding: 25px 0 25px 65px;
  margin-right: 25px;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
}

.side-menu {
  /*max-width: 300px;*/
  width: 300px;
  border: 1px solid #eb6877;
  border-radius: 10px;
  margin-bottom: 30px;
}
.side-menu.green-box {
  max-width: 300px;
  border: 1px solid #7fc168;
  border-radius: 10px;
  margin-bottom: 30px;
}
.side-menu .p_about:not(.link) {
  display: block;
  max-width: 300px;
  background: url(../img/common/arrow01.png) no-repeat center right 20px/contain;
  background-size: 10px 11px;
  background-color: #eb6877;
  border-radius: 7px 7px 0 0;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.02em;
  padding: 15px 35px 15px 20px;
}
.side-menu.green-box .p_about:not(.link) {
  background: url(../img/common/arrow01.png) no-repeat center right 20px/contain;
  background-size: 10px 11px;
  background-color: #7fc168;
}
.side-menu.archive-menu .p_about:not(.link) {
  background-image: none;
  background-color: #eb6877;
  text-align: center;
}
.side-menu.archive-menu.green-box .p_about:not(.link) {
  background-image: none;
  background-color: #7fc168;
  text-align: center;
}
.side-menu.archive-menu .p_about:not(.link) .fa-tag, .side-menu.archive-menu .p_about:not(.link) .fa-calendar-alt {
  margin-right: 5px;
}
.side-menu.one-item .p_about:not(.link) {
  border-radius: 7px;
}

.link.p_about {
  border-bottom: none;
}

.side-menu .p_about.link a {
  display: block;
  max-width: 300px;
  background: url(../img/common/arrow01.png) no-repeat center right 20px/contain;
  background-size: 10px 11px;
  background-color: #eb6877;
  border-radius: 7px 7px 0 0;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.02em;
  padding: 15px 35px 15px 20px;
}
.side-menu.green-box .p_about.link a {
  background: url(../img/common/arrow01.png) no-repeat center right 20px/contain;
  background-size: 10px 11px;
  background-color: #7fc168;
}
.side-menu.archive-menu .p_about.link a {
  background-image: none;
  background-color: #eb6877;
  text-align: center;
}
.side-menu.archive-menu.green-box .p_about.link a {
  background-image: none;
  background-color: #7fc168;
  text-align: center;
}
.side-menu.archive-menu .p_about.link a .fa-tag, .side-menu.archive-menu .p_about.link a .fa-calendar-alt {
  margin-right: 5px;
}
.side-menu.one-item .p_about.link a {
  border-radius: 7px;
}
.side-menu .under a {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  padding: 25px 0 25px 60px;
}
.side-menu.green-box .under a {
  background: url(../img/common/arrow02-green.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
}
.side-menu .under.active a {
  display: block;
  font-weight: bold;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  background-color: #fffcee;
  color: #eb6877;
}
.side-menu .under:last-child.active a {
  display: block;
  font-weight: bold;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  background-color: #fffcee;
  color: #eb6877;
  border-radius: 0 0 7px 7px;
}
.side-menu.green-box .under.active a {
  background: url(../img/common/arrow02-green.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  background-color: #f9fbf9;
  color: #7fc168;
}
.side-menu .under.active a span {
  border-bottom: 1px solid #eb6877;
}
.side-menu.green-box .under.active a span {
  border-bottom: 1px solid #7fc168;
}
.side-menu .under {
  border-bottom: 2px dotted #e8e8e8;
}
.side-menu .under:last-child {
  border-bottom: none;
}

.login .login__box.gray {
  border: 2px solid #e8e8e8;
  background-color: #fff;
  border-radius: 10px;
}
.login .login__box .login__box__hdg.gray {
  color: #332c2b;
  background-color: #f8f8f8;
}

@media all and (-ms-high-contrast: none) {
  .side-content-box .login {
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  .side-content-box {
    margin: 20px auto 0;
  }
  .main-content {
    max-width: 100%;
    width: 100%;
  }
  .about_under__link {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
/*--------------------
      about outline
---------------------*/
.about.outline {
  width: 100%;
}

.about_article__headding {
  font-size: 2.8rem;
  letter-spacing: 0.02em;
  font-weight: bold;
  padding-left: 20px;
  border-left: 6px solid #eb6677;
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
}
.about_article__headding.green-box {
  font-size: 2.8rem;
  letter-spacing: 0.02em;
  font-weight: bold;
  padding-left: 20px;
  border-left: 6px solid #80c269;
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
}
.about_article__headding:after {
  position: absolute;
  content: "";
  border-bottom: 2px dotted #e8e8e8;
  width: calc(100% - 20px);
  bottom: 0;
  left: 20px;
}

.group_table {
  width: 100%;
  margin-bottom: 40px;
}
.group_table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e8e8e8;
}
.group_table th {
  max-width: 140px;
  width: 100%;
  padding: 25px 10px;
  text-align: left;
  letter-spacing: 0.02em;
  line-height: 2;
}
.group_table td {
  width: calc(100% - 140px);
  padding: 25px 10px 25px 0;
  letter-spacing: 0.02em;
  line-height: 2;
}

@media all and (-ms-high-contrast: none) {
  .group_table th {
    width: 140px;
    padding: 25px 10px;
    text-align: left;
    letter-spacing: 0.02em;
    line-height: 2;
  }
}
.group-box {
  margin-bottom: 70px;
}

.group-box-item {
  max-width: 440px;
  width: 100%;
  margin-bottom: 30px;
}

.group_headding {
  font-size: 1.8rem;
  letter-spacing: 0.02rem;
  font-weight: bold;
  background: #fff5f5;
  border-radius: 4px;
  padding: 20px 25px;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
}
.group_headding:before {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  background: #eb6677;
  top: calc(50% - 2px);
  left: -7px;
}
.group_headding.green-box {
  background: #f5fcf5;
}
.group_headding.green-box:before {
  background: #80c269;
}

.group-box-item p {
  letter-spacing: 0.02em;
  line-height: 2;
}
.group-box-item.map .gmap {
  margin-bottom: 20px;
}
.group-box-item.logo .logo-box {
  width: 100%;
  height: 230px;
  background: url(../img/about/logo.png) no-repeat center center/contain;
  background-size: 174px 104px;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.chart-box {
  width: 100%;
  text-align: center;
  border: 1px solid #e8e8e8;
  margin-bottom: 100px;
  padding: 55px 15px;
}

.member_table {
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 930px;
  margin-bottom: 20px;
}
.member_table .member_table__heading th {
  letter-spacing: 0.02em;
  line-height: 2;
  color: #fff;
  background: #ee7b8a;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid #fff;
}
.member_table .member_table__heading th.position {
  width: 140px;
  padding: 10px 50px;
}
.member_table .member_table__heading th.name, .member_table .member_table__heading th.branch {
  width: 200px;
  padding: 10px 80px;
}
.member_table .member_table__heading th.belong {
  width: 388px;
  padding: 10px 170px;
  border-right: none;
}

.member_table__content {
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.member_table__content td {
  padding: 10px 0;
}
.member_table__content td.position {
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  background: #fff5f5;
  text-align: center;
  border-right: 1px solid #e8e8e8;
}
.member_table__content td.name, .member_table__content td.branch, .member_table__content td.belong {
  letter-spacing: 0.02em;
  line-height: 2;
  font-weight: 500;
  padding-left: 20px;
  border-right: 1px solid #e8e8e8;
}
.member_table__content:nth-child(2n+1) td.name, .member_table__content:nth-child(2n+1) td.branch, .member_table__content:nth-child(2n+1) td.belong {
  background: #fcfcfc;
}

/*--------------------
      social_worker
---------------------*/
.about-social-worker {
  width: 100%;
  margin-bottom: 55px;
}
.about-social-worker .text-box {
  width: calc(100% - 420px);
  margin-bottom: 30px;
}
.about-social-worker .image-box {
  width: 380px;
}

.about-worker-title {
  font-size: 2rem;
  letter-spacing: 0.02em;
  line-height: 2;
  font-weight: bold;
  color: #eb6677;
  margin-bottom: 20px;
}

.about.social_worker .group_headding {
  padding: 15px 25px;
  margin-bottom: 20px;
}

.field-box {
  width: 100%;
  margin-bottom: 80px;
}

.field-list {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.field-list .field-list-image {
  width: 155px;
  text-align: center;
}
.field-list .field-list-text {
  width: calc(100% - 155px);
  padding: 45px 35px 45px 0;
}
.field-list .field-list-text .field-title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
}

.about.social_worker p span.symbol {
  padding-left: 15px;
  position: relative;
}
.about.social_worker p span.symbol:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #eb6677;
  top: 5px;
  left: 3px;
}

@media screen and (max-width: 768px) {
  .about-social-worker .text-box {
    width: 100%;
    margin-bottom: 30px;
  }
  .about-social-worker .image-box {
    width: 100%;
  }
  .about-social-worker .image-box img {
    width: 100%;
  }
}
@media screen and (max-width: 530px) {
  .field-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .field-list .field-list-image {
    width: 100%;
    text-align: left;
    padding-top: 45px;
    padding-left: 35px;
  }
  .field-list .field-list-text {
    width: 100%;
    padding: 45px 35px;
  }
}
/*--------------------
      introduction
---------------------*/
.introduction_under__link-box {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.link-bob-item {
  width: 290px;
  -webkit-box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.link-bob-item:last-child {
  margin-right: 0;
}

.link-box-image {
  width: 100%;
  height: 140px;
  border-radius: 7px 7px 0 0;
}
.link-box-image.soumu {
  background: url(../img/introduction/link-soumu.jpg) no-repeat center center/cover;
}
.link-box-image.katsudou {
  background: url(../img/introduction/link-katsudou.jpg) no-repeat center center/cover;
}
.link-box-image.jigyou {
  background: url(../img/introduction/link-jigyou.jpg) no-repeat center center/cover;
}

.link-box-list {
  width: 100%;
  padding: 30px 10px 30px 30px;
}
.link-box-list .link-b-title a {
  display: block;
  padding-left: 30px;
  background: url(../img/common/arrow02-pink.png) no-repeat center left/contain;
  background-size: 15px 15px;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
}
.link-box-list .link-s-title {
  display: none;
}

.logged-in .link-box-list .link-s-title {
  display: block;
}

.link-box-list .link-s-title a {
  display: block;
  padding-left: 35px;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2;
  font-weight: 500;
}
.link-box-list .link-s-title a:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 1px;
  background: #332c2b;
  top: 14px;
  left: 25px;
}

.side-menu .under .link-box-list {
  width: 100%;
  padding: 0;
}
.side-menu .under .link-box-list .link-b-title a {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  padding: 15px 0 15px 60px;
}
.side-menu.green-box .under .link-box-list .link-b-title a {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2;
  background: url(../img/common/arrow02-green.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  padding: 15px 0 15px 60px;
}
.side-menu .under .link-box-list .link-s-title a {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2;
  background: none;
  padding: 0 0 5px 70px;
}
.side-menu .under .link-box-list .link-s-title:last-child a {
  padding: 0 0 15px 70px;
}
.side-menu .under .link-box-list .link-s-title a:before {
  top: 12px;
  left: 60px;
}
.side-menu .under.active .link-box-list {
  background: #fffcee;
  border-radius: 0 0 7px 7px;
}
.side-menu .under.active .link-box-list .link-s-title a {
  color: #000;

  &.active {
    color: #7fc168
  }
}

@media screen and (max-width: 768px) {
  .link-bob-item {
    width: calc(50% - 30px);
    -webkit-box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
    box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 640px) {
  .link-bob-item {
    width: 100%;
    -webkit-box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
    box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
    margin-right: 0;
    margin-bottom: 30px;
    border-radius: 10px;
  }
}
/*--------------------------
      introduction under
---------------------------*/
.introduction-under .about-main p {
  letter-spacing: 0.02em;
  line-height: 2;
}
.introduction-under .about-main p span {
  border-bottom: 1px solid #eb6677;
}
.introduction-under .about-main p span a {
  color: #eb6677;
}
.introduction-under .about-main p span.b-none {
  border-bottom: none;
}
.introduction-under .about-main p span.b-none a {
  color: #000;
}

.introduction-s-headdinng {
  padding-left: 25px;
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
}

.partner_hiroshima .introduction-s-headdinng.fz22 {
  font-size: 2.2rem;
}
.partner_hiroshima .introduction-s-headdinng.fz22:before {
  top: 11px;
}

.introduction-s-headdinng:before {
  position: absolute;
  content: "";
  border: 6px solid #eb6677;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: calc(50% - 11px);
  left: 0;
}

.introduction_table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
}
.introduction_table dt {
  width: 140px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background: #fff5f5;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 20px;
}
.introduction_table dd {
  width: calc(100% - 140px);
  border-bottom: 1px solid #e8e8e8;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 20px 10px 20px 25px;
}
.introduction_table dd:nth-child(4n) {
  background: #fcfcfc;
}

@media screen and (max-width: 630px) {
  .introduction_table dt, .introduction_table dd {
    width: 100%;
  }
}
/*--------------------------
      training_event
---------------------------*/
.about.training_event .news-list.training {
  margin-bottom: 50px;
}
.event-archive .news-list.training .news-list__item a,
.about.training_event .news-list.training .news-list__item a {
  background-color: #fdf9f9;
}
.about.training_event .side-menu .under a {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  padding: 20px 0 20px 60px;
}
.about.training_event .side-menu .under.active a {
  display: block;
  font-weight: bold;
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  background-color: #fffcee;
  color: #eb6877;
}

/*--------------------------
      news detail
---------------------------*/
.news-detail-box {
  border-bottom: 2px dotted #e8e8e8;
  margin-bottom: 30px;
  padding-bottom: 5em;
}
.news-detail-box ul {
  margin-bottom: 20px;
}
.news-detail-box ul .date {
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  color: #bf9596;
  background: #EAE3E3;
  border-radius: 20px;
  padding: 2px 10px;
  margin-right: 10px;
}
.news-detail-box ul .cate {
  font-weight: bold;
  color: #eb6777;
  border: 1px solid #eb6777;
  padding: 2px 10px;
}
.news-detail-box.green-box ul .cate {
  font-weight: bold;
  color: #7fc168;
  border: 1px solid #7fc168;
  padding: 2px 10px;
}
.news-detail-box .introduction_table {
  margin-bottom: 90px;
}

.control-box {
  margin-bottom: 100px;
}
.control-box div {
  width: 100px;
}
.control-box .all {
  width: 220px;
}

.btn-prev {
  width: 100%;
  display: block;
  padding: 20px 10px 20px 30px;
  background: url(../img/common/btn-pagenavi-prev-pink.png) no-repeat center left/contain;
  background-size: 16px 16px;
  font-weight: bold;
}

.btn-back {
  display: block;
  width: 100%;
  background: #eb6877;
  color: #fff;
  border-radius: 30px;
  padding: 20px 10px;
  font-weight: bold;
  text-align: center;
}

.btn-next {
  width: 100%;
  display: block;
  padding: 20px 30px 20px 10px;
  background: url(../img/common/btn-pagenavi-next.png) no-repeat center right/contain;
  background-size: 16px 16px;
  font-weight: bold;
}

.control-box.green-box .btn-prev {
  width: 100%;
  display: block;
  padding: 20px 10px 20px 30px;
  background: url(../img/common/btn-pagenavi-prev-green.png) no-repeat center left/contain;
  background-size: 16px 16px;
  font-weight: bold;
}
.control-box.green-box .btn-back {
  display: block;
  width: 100%;
  background: #80c269;
  color: #fff;
  border-radius: 30px;
  padding: 20px 10px;
  font-weight: bold;
  text-align: center;
}
.control-box.green-box .btn-next {
  width: 100%;
  display: block;
  padding: 20px 30px 20px 10px;
  background: url(../img/common/btn-pagenavi-next-green.png) no-repeat center right/contain;
  background-size: 16px 16px;
  font-weight: bold;
}

.btn-back .fa-bars {
  margin-right: 5px;
}

@media screen and (max-width: 530px) {
  .control-box div {
    width: 100%;
    margin-bottom: 10px;
  }
  .control-box .all {
    width: 100%;
  }
  .btn-prev, .btn-next {
    text-align: center;
    padding: 20px 30px 20px;
  }
}
/*--------------------------
      training
---------------------------*/
.b-d-box {
  width: 100%;
  border: 1px dotted #eb6777;
  padding: 25px 40px;
  border-radius: 10px;
}

.tarining-stop a {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #ff0101;
  border: 2px solid #ff0101;
  background: url(../img/common/arrow01-red.png) no-repeat center right 25px/contain;
  background-size: 13px 12px;
  background-color: #fff7f7;
  padding: 20px 40px 20px 25px;
  margin-bottom: 35px;
}
.tarining-stop a span {
  border-bottom: 1px solid #ff0101;
}

.training-list {
  width: 100%;
}
.training-list .training-list-item {
  width: calc(33.3333333333% - 35px);
  margin-right: 35px;
}
.training-list .training-list-item:last-child {
  margin-right: 0;
}

.bb-p {
  border-bottom: 1px solid #eb6777;
  word-break: break-all;
}
.bb-p a {
  color: #eb6777;
}

.about.training .about_under__link:nth-child(3n) {
  margin-right: 0;
}
.about.training .mail-box .mail-box-title {
  width: 170px;
}
.about.training .mail-box .mail-box-text {
  width: calc(100% - 170px);
}
.about.training .mail-box-title span.symbol {
  padding-left: 15px;
  position: relative;
}
.about.training .mail-box-title span.symbol:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #eb6677;
  top: 5px;
  left: 3px;
}
.about.training .introduction_table {
  width: 100%;
  margin-bottom: 100px;
  border: 1px solid #e8e8e8;
  border-bottom: none;
  border-right: none;
}
.about.training .introduction_table dt {
  width: 300px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background: #fff5f5;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 20px 30px;
}
.about.training .introduction_table dd {
  width: calc(100% - 300px);
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 20px 10px 20px 25px;
}
.about.training .introduction_table dd:nth-child(4n) {
  background: #fcfcfc;
}
.about.training .introduction_table dd span {
  border-bottom: 1px solid #eb6777;
}
.about.training .introduction_table dd span a {
  color: #eb6777;
}

@media screen and (max-width: 768px) {
  .training-list .training-list-item {
    width: calc(50% - 35px);
    margin-right: 35px;
  }
  .about.training .introduction_table dt, .about.training .introduction_table dd {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .training-list .training-list-item {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 425px) {
  .about.training .mail-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .about.training .mail-box dt {
    width: 100%;
  }
  .about.training .mail-box dd {
    width: 100%;
    padding-left: 15px;
  }
}
/*--------------------------
      training under
---------------------------*/
.trainig_table {
  margin-bottom: 0;
  border: 1px solid #e8e8e8;
}
.trainig_table li {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
}
.trainig_table li ul {
  width: 100%;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.trainig_table .trainig_table_title {
  width: 140px;
  background: #ee7d8b;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 25px 0;
  color: #fff;
  border-bottom: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.trainig_table .trainig_table_title .br-630 {
  display: block;
}
.trainig_table .trainig_table_text {
  width: calc(100% - 140px);
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 25px;
  border-bottom: none;
}
.trainig_table li:last-child .trainig_table_text {
  border-bottom: none;
}
.trainig_table li:nth-child(2n) .trainig_table_text {
  background: #fcfcfc;
}
.trainig_table .trainig_table_text span {
  border-bottom: 1px solid #eb6777;
}
.trainig_table .trainig_table_text span a {
  color: #eb6777;
}

@media screen and (max-width: 630px) {
  .trainig_table .trainig_table_title, .trainig_table .trainig_table_text {
    width: 100%;
  }
  .trainig_table .trainig_table_title .br-630 {
    display: none;
  }
}
/*--------------------------
      information
---------------------------*/
.about.information .news-list .news-list__item a {
  background-color: #fdf9f9;
}
.about.information .news-list.information .news-list__item a .date {
  background-color: #faf0f0;
}

/*--------------------------
      partner_hiroshima
---------------------------*/
.pdf-list {
  width: 100%;
}

.pdf-list-item a {
  display: block;
  width: 100%;
  letter-spacing: 0.02em;
  line-height: 3;
  background: url(../img/common/icn-pdf.png) no-repeat top 11px left/contain;
  background-size: 20px 22px;
  padding: 0 10px 0 30px;
}
.pdf-list-item a span {
  border-bottom: 1px solid #332c2b;
}

.word-list-item a {
  display: block;
  width: 100%;
  letter-spacing: 0.02em;
  line-height: 3;
  background: url(../img/common/icn-word.png) no-repeat top 11px left/contain;
  background-size: 23px 21px;
  padding: 0 10px 0 30px;
}
.word-list-item a span {
  border-bottom: 1px solid #332c2b;
}

.partner_table-wrapper {
  display: block;
  overflow-x: scroll;
  /*-ms-overflow-style: none; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/*.partner_table-wrapper::-webkit-scrollbar {
    display:none;
}*/
.partner_table {
  width: 930px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.partner_table thead th {
  background: #80c269;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: center;
  padding: 15px 10px;
}
.partner_table thead th.title {
  width: 400px;
}
.partner_table thead th.allotype {
  width: 322px;
}
.partner_table thead th.case {
  width: 200px;
}
.partner_table tbody td {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.partner_table tbody td.title {
  background: #f5fcf5;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: center;
}
.partner_table tbody td.allotype {
  padding: 10px 20px;
  letter-spacing: 0.02em;
  line-height: 2;
}
.partner_table tbody tr:nth-child(2n) td.allotype {
  background: #fcfcfc;
}
.partner_table tbody td.case {
  padding: 10px 20px;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: right;
}
.partner_table tbody tr:nth-child(2n) td.case {
  background: #fcfcfc;
}
.partner_table tbody td.subtotal {
  background: #c4c4c4;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
}
.partner_table tbody td.subtotal-case {
  background: #c4c4c4;
  color: #fff;
  text-align: right;
  padding: 10px 20px;
}
.partner_table tfoot td {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.partner_table tfoot td.total {
  background: #929292;
  color: #fff;
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 15px 20px;
}
.partner_table tfoot td.total-case {
  background: #929292;
  color: #fff;
  text-align: right;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 15px 20px;
}

.about_under__link.green-link {
  display: block;
  max-width: 290px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  padding: 25px 0 25px 65px;
  margin-right: 25px;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  background: url(../img/common/arrow02-green.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
}

.about.partner_hiroshima .about_under__link.green-link:last-child {
  margin-right: 0;
  /* line-height: 1.4;
  padding: 18px 0 15px 65px; */
}

.about_under__link.list-only {
  background: url(../img/common/arrow02-pink.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  background-color: #fcf9de;
  -webkit-box-shadow: 4px 4px 0px 0 rgb(222, 107, 124);
  box-shadow: 4px 4px 0px 0 rgb(222, 107, 124);
  border: 1px solid #de6b7c;
  color: #de6b7c;
}
.about_under__link.list-only .fa-lock {
  margin-left: 5px;
  font-size: 1.6rem;
}

.side-menu.green-box .fa-lock {
  font-size: 1.6rem;
  margin-left: 5px;
  color: #c6c6c6;
}

.list-only-banner {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.list-only-banner span {
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .about_under__link.green-link {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
/*----------------------------------------------
      partner_hiroshima seinen_kouken_seido
-----------------------------------------------*/
.partner_hiroshima .introduction-s-headdinng {
  padding-left: 25px;
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
}
.partner_hiroshima .introduction-s-headdinng:before {
  position: absolute;
  content: "";
  border: 6px solid #80c269;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: 6px;
  left: 0;
}
.partner_hiroshima .b-d-box {
  width: 100%;
  border: 1px dotted #80c269;
  padding: 25px 40px;
  border-radius: 10px;
}
.partner_hiroshima .b-d-box p.symbol {
  padding-left: 15px;
  position: relative;
}
.partner_hiroshima .b-d-box p.symbol:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #7fc168;
  top: 8px;
  left: 3px;
}

.btn-portal {
  display: block;
  max-width: 270px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/icn-window-green.png) no-repeat center right 30px/contain;
  background-size: 12px 9px;
  padding: 15px 40px;
}

.btn-support {
  display: block;
  max-width: 465px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/icn-window-green.png) no-repeat center right 30px/contain;
  background-size: 12px 9px;
  padding: 15px 40px;
}

@media screen and (max-width: 375px) {
  .partner_hiroshima .b-d-box {
    width: 100%;
    border: 1px dotted #80c269;
    padding: 25px 20px;
    border-radius: 10px;
  }
}
/*----------------------------------------------
      partner_hiroshima report
-----------------------------------------------*/
.report-list-box {
  margin-bottom: 60px;
}
.report-list-box ol li {
  list-style: decimal;
  margin-left: 15px;
  width: calc(100% - 15px);
}

.report-text-box {
  width: calc(100% - 375px);
  letter-spacing: 0.02em;
  line-height: 2;
  margin-bottom: 20px;
}

.report-picture-box {
  width: 350px;
}
.report-picture-box .report-picture {
  width: 100%;
}
.report-picture-box img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.report-picture-box .report-picture {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .report-text-box {
    width: 100%;
    letter-spacing: 0.02em;
    line-height: 2;
  }
  .report-picture-box {
    width: 350px;
  }
}
@media screen and (max-width: 560px) {
  .report-picture-box {
    width: 100%;
  }
}
/*----------------------------------------------
      partner_hiroshima meibo
-----------------------------------------------*/
.about.partner_hiroshima.meibo .about_under__link.green-link:nth-child(3n) {
  margin-right: 0;
  line-height: 2;
  padding: 25px 0 25px 65px;
}

/*--------------------------
      evaluation
---------------------------*/
.window-link-box li a {
  color: #5fa945;
}
.window-link-box li a span {
  border-bottom: 1px solid #5fa945;
}
.window-link-box li img {
  width: 14px;
  height: 11px;
  margin-left: 10px;
  margin-right: 10px;
}

.evaluation_table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
}
.evaluation_table dt {
  width: 140px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background: #80c269;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 20px;
  color: #fff;
}
.evaluation_table dd {
  width: calc(100% - 140px);
  border-bottom: 1px solid #e8e8e8;
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 20px 10px 20px 25px;
}
.evaluation_table dd:nth-child(4n) {
  background: #fcfcfc;
}

@media screen and (max-width: 630px) {
  .evaluation_table dt, .evaluation_table dd {
    width: 100%;
  }
}
/*--------------------------
      lifefixing
---------------------------*/
.lifefixing .b-d-box p span {
  border-bottom: none;
}

.btn-mail {
  display: block;
  max-width: 270px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/arrow01-green.jpg) no-repeat center right 30px/contain;
  background-size: 10px 11px;
  padding: 15px 45px 15px 40px;
}

.lifefixing .lity-image-box {
  width: 100%;
}
.lifefixing .lity-image-box .lity-image-box-item {
  position: relative;
  width: 460px;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
}
.lifefixing .lity-image-box .lity-image-box-item .big {
  position: absolute;
  border: 1px solid #999999;
  border-radius: 5px;
  color: #999999;
  background: #fff;
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: center;
  width: 80px;
  padding: 1px 0;
  bottom: 10px;
  right: 15px;
}
.lifefixing .lity-image-box .lity-image-box-item .big .fa-search-plus {
  margin-right: 5px;
}
.lifefixing .lity-image-box .lity-image-box-item a {
  display: block;
  text-align: center;
}

.report-picture-text {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 5px;
  letter-spacing: 0.02em;
  line-height: 2;
}

/*--------------------------
      homeless
---------------------------*/
.btn-kizo {
  display: block;
  max-width: 235px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/arrow01-green.jpg) no-repeat center right 30px/contain;
  background-size: 10px 11px;
  padding: 15px 45px 15px 40px;
}

.homeless-active-box {
  width: 100%;
}

.active-table-box {
  width: 600px;
}

.active-figure-box {
  width: 290px;
}
.active-figure-box figure {
  width: 100%;
}
.active-figure-box figure img {
  width: 100%;
}
.active-figure-box figcaption {
  text-align: center;
  margin-top: 5px;
}

.about-worker-title.green-title {
  font-size: 2rem;
  letter-spacing: 0.02em;
  line-height: 2;
  font-weight: bold;
  color: #7fc168;
  margin-bottom: 20px;
}

.homeless .b-d-box.bath {
  width: 100%;
  border: 1px dotted #80c269;
  padding: 25px 265px 25px 40px;
  border-radius: 10px;
  background: url(../img/homeless/imag02.png) no-repeat center right 75px/contain;
  background-size: 160px 115px;
}

.about.partner_hiroshima.homeless .about_under__link.green-link:last-child {
  margin-right: 0;
  line-height: 2;
  padding: 25px 0 25px 65px;
}

@media screen and (max-width: 930px) {
  .homeless .b-d-box.bath {
    width: 100%;
    border: 1px dotted #80c269;
    border-radius: 10px;
    background: url(../img/homeless/imag02.png) no-repeat bottom 25px right 75px/contain;
    background-size: 160px 115px;
  }
}
@media screen and (max-width: 650px) {
  .homeless .b-d-box.bath {
    width: 100%;
    border: 1px dotted #80c269;
    padding: 25px 40px 95px 40px;
    border-radius: 10px;
    background: url(../img/homeless/imag02.png) no-repeat bottom 25px right 40px/contain;
    background-size: 160px 115px;
  }
}
@media screen and (max-width: 425px) {
  .active-figure-box {
    width: 100%;
  }
}
/*--------------------------
      homeless donation
---------------------------*/
.donation_table-wrapper {
  display: block;
  overflow-x: scroll;
  /*-ms-overflow-style: none; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.donation_table {
  width: 930px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.donation_table thead th {
  background: #80c269;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: center;
  padding: 15px 10px;
}
.donation_table thead th.title {
  width: 140px;
}
.donation_table thead th.allotype {
  width: 490px;
}
.donation_table thead th.case {
  width: 300px;
}
.donation_table tbody td {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.donation_table tbody td.title {
  background: #f5fcf5;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: center;
}
.donation_table tbody td.allotype {
  padding: 10px 20px;
  letter-spacing: 0.02em;
  line-height: 2;
}
.donation_table tbody tr:nth-child(2n) td.allotype {
  background: #fcfcfc;
}
.donation_table tbody td.case {
  padding: 10px 20px;
  letter-spacing: 0.02em;
  line-height: 2;
}
.donation_table tbody tr:nth-child(2n) td.case {
  background: #fcfcfc;
}

/*--------------------------
      homeless donation
---------------------------*/
.event-stop-box {
  border: 2px solid #ff0000;
  background: #fff7f7;
  padding: 30px 20px;
}

.event-stop-title {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.6;
  margin-bottom: 25px;
  color: #ff0000;
}

.event-stop-text {
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  line-height: 2;
  color: #ff0000;
}

.partner_hiroshima span.b-b-g {
  border-bottom: 1px solid #7fbf66;
}
.partner_hiroshima span.b-b-g a {
  color: #7fbf66;
}

.word-list {
  width: 100%;
}

.word-list-item a {
  display: block;
  width: 100%;
  letter-spacing: 0.02em;
  line-height: 3;
  background: url(../img/common/icn-word.png) no-repeat center left/contain;
  background-size: 23px 21px;
  padding: 0 10px 0 30px;
}
.word-list-item a span {
  border-bottom: 1px solid #332c2b;
}

.picture-box {
  width: 100%;
}

.picture-item {
  width: calc(50% - 15px);
  margin-bottom: 60px;
}
.picture-item figure {
  width: 100%;
}
.picture-item figure img {
  width: 100%;
}
.picture-item figcaption {
  margin-top: 10px;
  line-height: 2;
}

@media screen and (max-width: 630px) {
  .picture-item {
    width: 100%;
    margin-bottom: 60px;
  }
}
/*--------------------------
      dementia
---------------------------*/
.new-info-box {
  width: 100%;
}

.group_headding.green-box span {
  border-bottom: 1px solid #ff0000;
  color: #ff0000;
}

.dementia-box {
  border: 1px solid #e8e8e8;
  padding: 35px 10px;
}

.dementia-box-title {
  font-size: 2rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
  text-align: center;
  font-weight: 700;
}

.dementia-image {
  width: 100%;
  text-align: center;
}

.dementia-list {
  width: 735px;
  margin: 0 auto;
}

.dementia-item {
  width: 367.5px;
  padding: 25px 20px;
  border-bottom: 2px dotted #e8e8e8;
}
.dementia-item:last-child {
  border-bottom: none;
}
.dementia-item:nth-child(2n+1) {
  border-right: 2px dotted #e8e8e8;
}

.dementia-item-title {
  font-size: 2rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 10px;
}
.dementia-item-title.is-1 {
  color: #ff0000;
}
.dementia-item-title.is-2 {
  color: #238c00;
}
.dementia-item-title.is-3 {
  color: #006dd9;
}
.dementia-item-title.is-4 {
  color: #00c060;
}
.dementia-item-title.is-5 {
  color: #e81c91;
}

.dementia-item-text {
  letter-spacing: 0.02em;
  line-height: 1.6;
}

.add-box {
  width: 100%;
}
.add-box .b-d-box {
  width: calc(50% - 5px);
  text-align: center;
}

.add-tel .fa-phone-alt {
  color: #80c269;
  font-size: 2rem;
  margin-right: 5px;
}
.add-tel a {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  line-height: 1.4;
}

.add-mail .fa-envelope {
  color: #80c269;
  font-size: 2rem;
  margin-right: 5px;
}
.add-mail a {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  line-height: 1.4;
}

.pc_320, .sp_550, .sp_425 {
  display: none;
}

.sp_320 {
  display: block;
}

@media screen and (max-width: 800px) {
  .dementia-list {
    width: 100%;
  }
  .dementia-item {
    width: 100%;
    padding: 25px 20px;
    border-bottom: 2px dotted #e8e8e8;
  }
  .dementia-item:nth-child(2n+1) {
    border-right: none;
  }
}
@media screen and (max-width: 767px) {
  .add-box .b-d-box {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .sp_550 {
    display: block;
  }
}
@media screen and (max-width: 425px) {
  .sp_425 {
    display: block;
  }
}
@media screen and (max-width: 320px) {
  .pc_320 {
    display: block;
  }
  .sp_320 {
    display: none;
  }
}
/*--------------------------
      branch
---------------------------*/
.branch .about_under__link.pink-link {
  display: block;
  max-width: 100%;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  box-shadow: 4px 4px 0px 0 rgb(232, 232, 232);
  padding: 25px;
  margin-right: 0;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  background: none;
  background-size: 15px 15px;
}

.branch-image-box {
  width: 100%;
  text-align: center;
}

.branch .about_under__link.pink-link span {
  font-size: 1.5rem;
  font-weight: 500;
}
.branch .side-menu.green-box .under a {
  background: url(../img/common/arrow02-green.png) no-repeat top 33px left 30px/contain;
  background-size: 15px 15px;
}
.branch .side-menu.green-box .under.active a {
  background-color: #f9fbf9;
}

.about.partner_hiroshima.branch .about_under__link.pink-link:last-child {
  margin-right: 0;
  line-height: 2;
  padding: 25px;
}

.about_hdg__headding.green-box span {
  color: #7fc168;
  font-size: 1.6rem;
}

/*--------------------------
      member
---------------------------*/
.member .about_hdg__headding {
  color: #f4970b;
}
.member .news-list.information .news-list__item a {
  background-image: url(../img/common/arrow02-orange.png);
}
.member .news-list.information .news-list__item a .cate {
  background-color: #f4970b;
}
.member .pagenavi-area.member-navi .wp-pagenavi span.current {
  border: 0px;
  color: #fff;
  background: #f4970b;
}
.member .pagenavi-area .wp-pagenavi a.nextpostslink {
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4;
  display: block;
  text-decoration: none;
  padding: 1.2em;
  background: url(../img/common/btn-pagenavi-next-orange.png) no-repeat center center/contain;
  background-size: 16px 16px;
  border: none;
}
.member .side-menu {
  max-width: 300px;
  border: 1px solid #f4970b;
  border-radius: 10px;
  margin-bottom: 30px;
}
.member .side-menu.archive-menu .p_about {
  background-image: none;
  background-color: #f4970b;
  text-align: center;
}
.member .side-menu .under a {
  background: url(../img/common/arrow02-orange.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
}
.member .side-menu .under.active a {
  display: block;
  font-weight: bold;
  background: url(../img/common/arrow02-orange.png) no-repeat center left 30px/contain;
  background-size: 15px 15px;
  background-color: #faf9f5;
  color: #f3970b;
}
.member .side-menu .under.active a span {
  border-bottom: 1px solid #f3970b;
}

/* 未定css */
/*--------------------------
      about entry
---------------------------*/
.entry-merit-box {
  margin-bottom: 60px;
}

.entry-merit {
  width: 100%;
  padding: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.entry-merit:last-child {
  margin-bottom: 0;
}

.illust {
  width: 70px;
  display: none;
}

/*.illust{
  width: 70px;
}*/
.merit-text-box {
  width: 100%;
}

/*.merit-text-box{
  width: calc( 100% - 100px );
}*/
.merit-b-text {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.6;
  margin-bottom: 10px;
}

.merit-s-text {
  letter-spacing: 0.02em;
  line-height: 2;
}

.group-box-item p span {
  border-bottom: 1px solid #eb6677;
}
.group-box-item p span a {
  color: #eb6677;
}

.about.entry p.symbol {
  padding-left: 15px;
  position: relative;
}
.about.entry p.symbol:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #eb6677;
  top: calc(50% - 4px);
  left: 3px;
}

.link {
  border-bottom: 1px solid #eb6677;
}
.link a {
  color: #eb6677;
}

@media screen and (max-width: 767px) {
  .illust {
    margin-bottom: 10px;
  }
  .merit-text-box {
    width: 100%;
  }
}
/*--------------------------
      about entry
---------------------------*/
.form_wrapper {
  width: 100%;
}

.about_entry_form {
  width: 100%;
  margin-bottom: 30px;
}
.about_entry_form tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 20px 10px;
  border-bottom: 1px solid #e8e8e8;
}

.form__th {
  width: 220px;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 15px;
}
.form__th span {
  color: #ff0000;
  display: none;
}
.form__th.req span {
  color: #ff0000;
  display: block;
}

.form__td {
  width: calc(100% - 220px);
}
.form__td.radio-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.form__td.radio-box span.box {
  width: 100%;
}
.form__td span {
  width: 100%;
}
.form__td.radio-box span {
  width: 110px;
}

.smf-button-control__control,
.form_btn {
  display: block;
  max-width: 260px;
  width: 100%;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
  border-radius: 30px !important;
  background: url(../img/common/arrow01.png) no-repeat center right 30px/contain !important;
  background-size: 10px 11px !important;
  background-color: #eb6877;
  padding: 15px 40px !important;
  color: #fff !important;
  margin: 0 auto;
  text-align: center;
  border: none;
}

.mwform-radio-field {
  padding: 3px 0;
  display: block;
}

.partner_hiroshima .mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}

.mwform-radio-field input[type=radio] {
  display: none;
}
.mwform-radio-field .mwform-radio-field-text {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  white-space: nowrap;
  line-height: 2;
}
.mwform-radio-field .mwform-radio-field-text::after, .mwform-radio-field .mwform-radio-field-text::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mwform-radio-field .mwform-radio-field-text::after {
  left: 0;
  margin-top: -10px;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
}
.mwform-radio-field .mwform-radio-field-text::before {
  left: 5px;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: #ec6d74;
  border-radius: 100%;
  opacity: 0;
  z-index: 1;
}

.about_entry_form.green_table .mwform-radio-field .mwform-radio-field-text::before {
  left: 5px;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: #80c269;
  border-radius: 100%;
  opacity: 0;
  z-index: 1;
}

.mwform-radio-field input[type=radio]:checked + .mwform-radio-field-text::before {
  opacity: 1;
}

.mwform-checkbox-field input:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
}
.mwform-checkbox-field [type=checkbox] {
  display: none;
}
.mwform-checkbox-field label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
.mwform-checkbox-field label::before {
  width: 21px;
  height: 21px;
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  background: #fafafa;
  border: 1px solid #e4e4e4;
}

.mwform-checkbox-field-text::after {
  width: 11px;
  height: 6px;
  border: 4px solid #eb6c73;
  content: "";
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 4px;
  top: 3px;
  border-top: none;
  border-right: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

input[type=text].addr {
  max-width: 520px;
}
input[type=tel].form-tel {
  max-width: 80px;
}
input[type=tel].zip {
  max-width: 110px;
}

.smf-item__controls {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.contact-tel {
  font-size: 3.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: center;
}
.contact-tel .fa-phone-alt {
  color: #eb6777;
  font-size: 2.3rem;
  margin-right: 5px;
  margin-bottom: 3px;
}
.contact-tel span {
  color: #eb6777;
}

.group-box-item p.contact-tel span, .group-box-item p.contact-fax span {
  border-bottom: none;
}

.contact-fax {
  font-size: 3.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: center;
}
.contact-fax .fa-fax {
  color: #eb6777;
  font-size: 2.3rem;
  margin-right: 5px;
  margin-bottom: 3px;
}
.contact-fax span {
  color: #eb6777;
}

@media screen and (max-width: 1260px) {
  .form_wrapper {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .form__th {
    width: 100%;
    padding-bottom: 15px;
  }
  .form__td {
    width: 100%;
  }
}
/*--------------------------
      offer
---------------------------*/
.offer-tel {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.offer-tel .fa-phone-alt {
  color: #80c269;
  font-size: 2.2rem;
  margin-right: 5px;
}

.s-f-custom {
  overflow: hidden;
  width: 180px;
}
.s-f-custom select {
  width: 100%;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.s-f-custom select::-ms-expand {
  display: none;
}

.form__td.s-f-custom {
  position: relative;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  background: #fafafa;
}
.form__td.s-f-custom::before {
  position: absolute;
  top: 1.3em;
  right: 0.9em;
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #d4d4d4;
  pointer-events: none;
}
.form__td.s-f-custom:after {
  position: absolute;
  top: 0;
  right: 2.5em;
  bottom: 0;
  width: 1px;
  content: "";
  border-left: 1px solid #e8e8e8;
}
.form__td.s-f-custom select {
  padding: 8px 38px 8px 8px;
  color: #332c2b;
}

.b-bb {
  border-bottom: 1px solid #332c2b;
}

input[type=text].b-textBox {
  max-width: 520px;
}

.form_btn_green {
  display: block;
  max-width: 260px;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
  border-radius: 30px;
  background: url(../img/common/arrow01.png) no-repeat center right 30px/contain;
  background-size: 10px 11px;
  background-color: #80c269;
  padding: 15px 40px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  border: none;
}

.table_wrapper {
  display: block;
  overflow-x: scroll;
  /* -ms-overflow-style: none; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.kobo_table {
  width: 930px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.kobo_th_100 {
  width: 100px;
  background: #f5fcf5;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 15px 0;
  text-align: center;
}

.kobo_th_80 {
  width: 80px;
  background: #f5fcf5;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 15px 0;
  text-align: center;
}

.kobo_th_218 {
  width: 218px;
  background: #f5fcf5;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 2;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 15px 0;
  text-align: center;
}

.kobo_table td {
  letter-spacing: 0.02em;
  line-height: 2;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 15px 0;
  text-align: center;
}

.partner_hiroshima.seminar .evaluation_table {
  margin-bottom: 80px;
}
.partner_hiroshima.seminar .evaluation_table dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.meibo .form__td.radio-box span {
  width: 180px;
}

/*-------------------------------
  swpm-login-widget-form
--------------------------------*/
.swpm-login-form-inner {
  padding: 15px 25px 0;
}

.swpm-username-label, .swpm-password-label {
  display: none;
}

.swpm-username-input, .swpm-password-input {
  position: relative;
}

.swpm-username-input input, .swpm-password-input input {
  border-radius: 5px;
  padding-left: 60px;
}

.swpm-username-input:before {
  position: absolute;
  width: 50px;
  background: #dedede;
  content: "ID";
  text-align: center;
  padding: 12.5px 0;
  top: 0;
  left: 0;
  border-radius: 5px 0 0 5px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
}

.swpm-password-input:before {
  position: absolute;
  width: 50px;
  background: #dedede;
  content: "PASS";
  text-align: center;
  padding: 12.5px 0;
  top: 0;
  left: 0;
  border-radius: 5px 0 0 5px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
}

.swpm-remember-me, .swpm-forgot-pass-link, .swpm-join-us-link {
  display: none;
}

.swpm-login-submit input {
  position: relative;
  background-image: url(../img/common/arrow01.png), url(../img/common/login-bg.jpg);
  background-repeat: no-repeat;
  background-position: right 15px center, center center;
  background-size: auto, cover;
  width: 100%;
  border: none;
  display: block;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding: 17px 13px;
  border-radius: 30px;
  width: 100%;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.swpm-login-widget-logged {
  padding: 15px 25px 0;
}

.login__box.none {
  display: none;
}

.login .inner.none p, .login .btn02.none {
  display: none;
}
.login.on {
  width: 300px;
}

.swpm-logged-status, .swpm-logged-membership, .swpm-logged-expiry {
  display: none;
}

.swpm-edit-profile-link {
  display: none;
}
.swpm-edit-profile-link a {
  display: none;
}

.swpm-logged-logout-link a {
  position: relative;
  background-image: url(../img/common/arrow01.png), url(../img/common/login-bg.jpg);
  background-repeat: no-repeat;
  background-position: right 15px center, center center;
  background-size: auto, cover;
  width: 100%;
  border: none;
  display: block;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding: 17px 13px;
  border-radius: 30px;
  width: 100%;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.swpm-logged-username-label.swpm-logged-label {
  text-align: center;
  background: #dedede;
  padding: 7px 0;
  border-radius: 5px 5px 0 0;
}

.swpm-logged-username-value.swpm-logged-value {
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  outline: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  vertical-align: middle;
  height: 45px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  text-align: center;
}

.mebo-login .swpm-username-input input, .mebo-login .swpm-password-input input {
  max-width: 100%;
  width: 100%;
}
.mebo-login .swpm-login-submit {
  margin-top: 30px;
}
.mebo-login .swpm-logged-username-value.swpm-logged-value {
  max-width: 100%;
  width: 100%;
}

.tac {
  text-align: center;
}

.year .about_hdg__headding.green-box span {
  font-size: 3.6rem;
}

.member .news-detail-box ul .cate {
  color: #f4970b;
  border: 1px solid #f4970b;
}
.member .about_article__headding {
  border-left: 6px solid #f4970b;
}
.member .btn-back {
  background: #f4970b;
}
.member .btn-prev {
  background: url(../img/common/btn-pagenavi-prev-orange.png) no-repeat center left/contain;
  background-size: 16px 16px;
}
.member .btn-next {
  background: url(../img/common/btn-pagenavi-next-orange.png) no-repeat center right/contain;
  background-size: 16px 16px;
}

/*  210511 追記  */
.sitemap .sub-nav__list {
  margin-bottom: 15px;
}
.sitemap .sub-nav__list__item {
  margin-bottom: 5px;
}
.sitemap .intro-list .sub-nav__list {
  width: 33%;
}
.sitemap .sub-nav__list__item a:not(.link01--pink, .link01--green) {
  position: relative;
  padding-left: 15px;
}
.sitemap .sub-nav__list__item a:not(.link01--pink, .link01--green)::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 1px;
  top: calc(50% - 1px);
  left: 5px;
  background: #000;
}
.sitemap .intro-list .sub-nav__list .sub-nav__list__item:not(:first-child) {
  padding-left: 20px;
}
.sitemap .col02 {
  max-width: 650px;
  width: 100%;
}
.sitemap .sub-nav__cont:first-child {
  margin-bottom: 30px;
}

@media screen and (max-width: 625px) {
  .sitemap .intro-list .sub-nav__list {
    width: 50%;
  }
}
@media screen and (max-width: 425px) {
  .sitemap .intro-list .sub-nav__list {
    width: 100%;
  }
}
/* 220105 */
.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

/* 220715 */
.top-sns {
  padding-top: 30px;
}

.top-sns__list__item {
  width: calc((100% - 30px) / 3);
}

@media screen and (max-width: 768px) {
  .top-sns__list__item {
    width: 100%;
    text-align: center;
  }
  .top-sns__list__item:nth-of-type(n + 2) {
    margin-top: 15px;
  }
}
.f-sns {
  margin-top: 15px;
}

.f-sns__item a {
  display: block;
  background: #d726a2;
  width: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  border-radius: 50%;
  color: #fff !important;
}
.f-sns__item:nth-of-type(n + 2) {
  margin-left: 10px;
}
.f-sns__item.is-fb a {
  background: #3b5998;
}
.f-sns__item.is-youtube a {
  background: #ff0000;
}

@media screen and (min-width: 1201px) {
  header .f-sns {
    display: none;
  }
}
/*221012*/
.btn-E {
  display: block;
  max-width: 600px;
  margin: 0 auto 70px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border-radius: 30px;
  color: #fff;
  background: url(../img/common/icn-window-white.png) #eb6877 no-repeat center right 30px/contain;
  background-size: 10px 9px;
  padding: 15px 40px;
}

/* 221028 */
.dementia-tel {
  width: calc(50% - 5px);
}

.dementia-tel__link {
  display: block;
  background: -webkit-gradient(linear, left bottom, left top, from(rgb(128, 194, 105)), to(rgb(78, 212, 145)));
  background: linear-gradient(0deg, rgb(128, 194, 105) 0%, rgb(78, 212, 145) 100%);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  pointer-events: none;
  height: 200px;
}

.dementia-tel__title {
  background-color: #fff;
  display: inline-block;
  width: 100px;
  color: #80c269;
  line-height: 35px;
  border-radius: 5px;
}

.dementia-tel__icon {
  color: #80c269;
  font-size: 16px;
  display: inline-block;
  background-color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
}
.dementia-tel__icon::before {
  position: relative;
  top: 7px;
}

.dementia-tel__number {
  font-size: 46px;
}

.dementia-mail {
  width: calc(50% - 5px);
}

.dementia-mail__link {
  display: block;
  border: 1px dotted #80c269;
  padding: 20px;
  border-radius: 10px;
  height: 200px;
}

.dementia-mail__title {
  background-color: #80c269;
  display: inline-block;
  width: 100px;
  color: #fff;
  line-height: 35px;
  border-radius: 5px;
  margin-bottom: 1.5em;
}

.dementia-mail__text {
  font-size: 30px;
  margin-right: 0.5em;
  text-align: left;
  line-height: 1.2;
}

.dementia-mail__icon {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  background-color: #80c269;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
}
.dementia-mail__icon::before {
  position: relative;
  top: 7px;
}

@media screen and (max-width: 1024px) {
  .dementia-tel {
    width: 100%;
    margin-bottom: 10px;
  }
  .dementia-mail {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .dementia-tel__link, .dementia-mail__link {
    pointer-events: auto;
    height: auto;
    padding: 20px 10px;
  }
  .dementia-tel__number {
    font-size: 30px;
  }
  .dementia-mail__title {
    margin-bottom: 1em;
  }
  .dementia-mail__text {
    font-size: 20px;
  }
}
.dementia .form__th .red-inline {
  display: inline;
}
.dementia .mwform-checkbox-field-text {
  font-weight: bold;
  margin-left: 1em;
}
.dementia .mwform-radio-field .mwform-radio-field-text {
  font-weight: bold;
  padding-left: 40px;
}
.dementia .form__td .mwform-checkbox-field, .dementia .form__td .mwform-radio-field {
  display: inline-block;
  width: auto;
  margin-bottom: 20px;
  margin-right: 30px;
}
.dementia textarea[name=相談内容その他], .dementia textarea[name=当事者との関係その他] {
  height: 44px;
}
.dementia .mwform-checkbox-field label::before, .dementia .mwform-radio-field .mwform-radio-field-text::after {
  border: #e8e8e8 1px solid;
  background-color: #fafafa;
  width: 30px;
  height: 30px;
}
.dementia .mwform-checkbox-field label::before {
  top: -6px;
}
.dementia .mwform-radio-field .mwform-radio-field-text::after {
  top: 0;
}
.dementia .mwform-checkbox-field-text::after {
  left: 8px;
}
.dementia .mwform-radio-field .mwform-radio-field-text::before {
  width: 15px;
  height: 15px;
  left: 8px;
  top: 4px;
}
.dementia .form__td {
  padding-top: 15px;
}

.mwform-tel-field input[type=text], .mwform-zip-field input[type=text] {
  display: inline-block;
  width: auto;
}

.smf-button-control__control,
.form_btn--green {
  border: 2px solid #80c269 !important;
  background-color: #80c269 !important;
  cursor: pointer;
}

.smf-button-control__control:hover,
.form_btn--green:hover {
  color: #80c269 !important;
  background-color: #fff !important;
}

input[name=addr] {
  max-width: 520px !important;
}

.about_under__link.green-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_under__link--2line {
  line-height: 1.4 !important;
}

.btn-login {
  display: block;
  max-width: 360px;
  font-weight: bold;
  letter-spacing: 0.02;
  line-height: 1.4;
  border: 1px solid #dddddd;
  border-radius: 30px;
  background: url(../img/common/icn-window-green.png) no-repeat center right 30px/contain;
  background-size: 12px 9px;
  padding: 15px 40px;
  color: #000;
}

/* ==========================================================================
  202403 renewal
============================================================================*/
/*# sourceMappingURL=map/style.css.map */
