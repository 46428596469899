@use '../base/vars'as *;

.entry202403__cont {

    .entry202403__data {

        dt {
            padding: 2px 4px;
            margin-right: 10px;
            border-radius: 4px;
        }

        dt,
        dd {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &+.entry202403__data {
            margin-top: 4px;
        }
    }

    &:nth-of-type(1) {
        .entry202403__data {
            dt {
                background-color: #FEEFF2;
                color: #EB6877;
            }
        }
    }

    &:nth-of-type(2) {
        .entry202403__data {
            dt {
                background-color: #F0FAF0;
                color: #80C269;
            }
        }
    }

    &:nth-of-type(3) {
        .entry202403__data {
            dt {
                background-color: #FFF7E0;
                color: #FFBF00;
            }
        }
    }
}
