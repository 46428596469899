@use '../base/vars'as *;

.branch {
    .about_under__link.green-link {
        display: grid;
        grid-template-columns: 6em 1fr;
        gap: 1em;
        max-width: 100%;
        margin: 0;
        padding-right: 25px;
    }
}
