@use '../base/vars'as *;

.single-event {

    .category-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: .5em;

        &+.category-wrapper {
            margin-left: .5em;
        }
    }

    .side-content-box {
        display: none;

        @include media(sm) {
            display: block;
        }
    }

    .news-detail-box .introduction_table {
        margin-bottom: 0;
    }

    .button-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
        gap: 30px;
        justify-content: center;
        margin-top: 5em;
    }

    .button-wrapper {
        width: 100%;
        max-width: 340px;

        .btn01 {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 62px;
        }

        &.contact {}

        &.back {

            a {
                background-color: $white;
                border: 1px solid $pink;
                color: $pink;
            }

            span {
                padding-left: 8px;
            }
        }
    }

    .eventorganiser-event-meta {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 1em;
        width: 100%;
    }
}

.shibuCategory {
    >span {
        display: inline-block;
    }
}
