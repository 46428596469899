@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo, sans-serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors
//*------------------------------------*//
$white: #fff;
$pink: #EB6877;
$blue: #4373C2;



///*------------------------------------*//
//     breakpoints
//*------------------------------------*//
$breakpoints: (
    sm: 'screen and (min-width: 769px)',
    md: 'screen and (min-width: 1000px)',
    lg: 'screen and (min-width: 1200px)',
    xl: 'screen and (min-width: 1366px)',
    2xl: 'screen and (min-width: 1920px)',
) !default;


///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//フォントサイズ計算
// @function rem($size, $base: 16) {
//     @return math.div($size, $base)+rem;
// }

//pxをvwに変換するfunction
@function vw($size, $viewport: $wrapper) {
    $rate: calc(100 / $viewport);
    @return $rate * $size * 1vw;
}

//hover
@mixin hover {
    transition: all .5s ease-out;

    &:focus {
        @content;
    }

    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}
