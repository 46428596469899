@use '../base/vars'as *;

.gridbox {
    display: grid;
}

@for $i from 2 through 4 {
    .grid_col#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);
    }
}

@for $i from 2 through 4 {
    .grid_gap#{$i} {
        gap: #{$i * 10}px;
    }
}
