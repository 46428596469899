@use '../base/vars'as *;

.residence {
    .squareList {
        li {
            padding-left: 1em;
            position: relative;

            &::before {
                display: block;
                content: "";
                position: absolute;
                top: .4em ;
                left: 0;
                width: .8em;
                height: .8em;
                background: black;
            }
        }
    }

    address {
        font-style: normal;
    }
}
